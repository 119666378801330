<template>
  <div id="app">
    <div>
      <el-row
        :gutter="2"
        class="myElRow"
        style="display: flex; justify-content: center"
      >
        <el-col :span="3"
          ><div
            class="grid-content"
            style="
              text-align: center;
              line-height: 50px;
              color: white;
              cursor: pointer;
            "
            @click="home"
          >
            <img
              src="./assets/logo.png"
              alt="logo"
              style="height: 40px"
            />&nbsp;NHCCord
          </div></el-col
        >
        <el-col :span="5"
          ><div
            class="grid-content myelcol"
            style="text-align: center; line-height: 50px; display: inline-block"
          ></div
        ></el-col>
        <el-col :span="3"
          ><div
            class="grid-content myelcol"
            style="
              background-color: ;
              text-align: center;
              line-height: 50px;
              display: inline-block;
            "
          ></div
        ></el-col>
        <el-col :span="2"
          ><div
            class="grid-content myElCol"
            style="
              background-color: ;
              text-align: center;
              line-height: 50px;
              cursor: pointer;
            "
            @click="home"
          >
            Home
          </div></el-col
        >
        <el-col :span="2"
          ><div
            class="grid-content myElCol"
            style="
              background-color: ;
              text-align: center;
              line-height: 50px;
              cursor: pointer;
            "
            @click="browse"
          >
            <el-dropdown>
              <span
                class="el-dropdown-link"
                style="background-color: #1b3660; padding: 4px; color: white"
              >
                Browse<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div @click="browse1">Reaction Conditions</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="browse2">NHC-Catalysts</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="browse3">Reaction Modes</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div></el-col
        >
        <el-col :span="2"
          ><div
            class="grid-content myElCol"
            style="
              background-color: ;
              text-align: center;
              line-height: 50px;
              cursor: pointer;
            "
            @click="search"
          >
            Search
          </div></el-col
        >
        <el-col :span="2"
          ><div
            class="grid-content myElCol"
            style="
              background-color: ;
              text-align: center;
              line-height: 50px;
              cursor: pointer;
            "
            @click="downloadPage"
          >
            Download
          </div></el-col
        >
        <el-col :span="2"
          ><div
            class="grid-content myElCol"
            style="
              background-color: ;
              text-align: center;
              line-height: 50px;
              cursor: pointer;
            "
            @click="guide"
          >
            Guide
          </div></el-col
        >
        <el-col :span="2"
          ><div
            class="grid-content myElCol"
            style="
              background-color: ;
              text-align: center;
              line-height: 50px;
              cursor: pointer;
            "
            @click="about"
          >
            About
          </div></el-col
        >
      </el-row>
    </div>

    <div v-show="this.homeShow" class="home">
      <!-- 输入smiles返回图片
      <div ref="imageContainer" style="background-color:red;width:100px;height:100px;"><a @click="smilestopngfunction" >smilestopng</a></div> -->
      
      <div class="myintro myintro1">
        <img src="./assets/pic/home1.png" class="mypic" />
        <div class="mytitle"><h1 class="myh1">Datebase Introduction</h1></div>
        <div class="myp-div">
          <div class="fl">
            <p class="myp1">
              <strong>N-heterocyclic carbenes</strong> (NHC) play an
              increasingly important role in organic reactions due to their
              special electronic properties and steric requirements for their
              generation. Based on this, our database provides all the current
              organic reactions involving NHC catalyzed, showing the relevant
              properties of the reactions in an all-round way. We aim to create
              a comprehensive, systematic and convenient NHC database for
              organic synthesis chemists in the field of NHC catalysis..
            </p>
            <div>
              <span class="myfle">
                <div style="color: rgb(118, 113, 113); font-size: 17px"></div>
                <div style="color: rgb(118, 113, 113); font-size: 13px"></div>
              </span>
              <span class="myfle">
                <div style="color: rgb(118, 113, 113); font-size: 17px">
                  300+
                </div>
                <div style="color: rgb(118, 113, 113); font-size: 13px">
                  catalysts
                </div>
              </span>
              <span class="myfle">
                <div style="color: rgb(118, 113, 113); font-size: 17px">
                  1500+
                </div>
                <div style="color: rgb(118, 113, 113); font-size: 13px">
                  reactants
                </div>
              </span>
              <span class="myfle">
                <div style="color: rgb(118, 113, 113); font-size: 17px">
                  1500+
                </div>
                <div style="color: rgb(118, 113, 113); font-size: 13px">
                  reactions
                </div>
              </span>
            </div>
          </div>
          <div class="fr">
            <img src="./assets/icon/home2.png" class="myfig2" />
          </div>
        </div>

        <div class="mytitle">
          <h1 class="myh1">What are the main functions of our database?</h1>
        </div>
        <div class="myp-div">
          <ul style="margin: -22px">
            <li style="list-style: none">
              <p class="myp1">
                <i class="el-icon-position" style="transform: rotate(45deg)"></i
                >&nbsp; <strong>Data specificity</strong> : Collecting and
                organizing information about the NHC-catalyzed reaction system,
                such as reaction conditions, products, and mechanisms.
              </p>
            </li>
            <li style="list-style: none">
              <p class="myp1">
                <i class="el-icon-position" style="transform: rotate(45deg)"></i
                >&nbsp; <strong>Data dynamism</strong>: New data is regularly
                updated and added to ensure that the most up-to-date and
                accurate information is captured.
              </p>
            </li>
            <li style="list-style: none">
              <p class="myp1">
                <i class="el-icon-position" style="transform: rotate(45deg)"></i
                >&nbsp; <strong>Data convenience</strong>: You can quickly find
                the NHC-related reactions of interest in our database to obtain
                relevant information.
              </p>
            </li>
            <li style="list-style: none">
              <p class="myp1">
                <i class="el-icon-position" style="transform: rotate(45deg)"></i
                >&nbsp; <strong>Friendly interface</strong>: As we designed our
                website with full consideration of user experience, it is quick
                to use and convenient for you to find the reaction you need and
                obtain related information. And it's free for users, no
                registration is required to use it.
              </p>
            </li>
          </ul>
        </div>
        <div class="mytitle"><h1 class="myh1">Contact Us</h1></div>
        <div class="myp-div"  style="justify-content:center;">
          <p class="myp1">
            If you have any problems in using it, feel free to contact <a href="mailto:gefei_hao@foxmail.com">us.</a>
          </p>
        </div>
      </div>
    </div>

    <div
      v-show="this.searchBox"
      class="searchBox"
      style="display: flex; justify-content: center"
    >
      <div class="molecule" style="">
        <div style="background-color: ">
          <select name="selectAll" style="height: 42px">
            <option value="all" name="0" selected>All</option>
            <option value="reactions" name="1">Reactions</option>
            <option value="references" name="2">References</option>
          </select>
          
          <!--搜索框调用onSearch-->
          <a-input-search
            class="input-search"
            v-model="smiles"
            placeholder="Enter keywords, smiles, title, DCE, DABCO, coupling, doi …
"
            size="large"
            @search="onSearch"
            style="width: 1100px; border: 1px solid rgb(139, 145, 182)"
          >
            <template #enterButton>
              <a-button
                style="background-color: rgb(20, 54, 95); color: white"
                icon="search"
                >search</a-button
              >
            </template>
          </a-input-search>
        </div>
        <!--画板-->
        <div class="flex" style="justify-content: space-between">
          <div class="left_content" style="">
            <a-tabs
              :type="'card'"
              v-model="activeKey"
              @change="changeTab"
            ></a-tabs>
            <div style="width: 650px">
              <!-- 使用src="./standalone/index.html"这个路径才行,使用src="../dist/standalone/index.html"这个路径会报错,不知道为什么,可能哪儿引入了某个路径 -->
              <iframe
                class="frame"
                id="idKetcher"
                src="./standalone/index.html"
                width="650" height="600"
                v-show="activeKey === 'ketcher'"
              />
            </div>
          </div>
          <div
            class="right_content"
            style="display: flex; align-items: center; justify-content: center;margin-left:20px;"
          >
            <img
              src="./assets/img/c.jpg"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
        <div class="mycon">
          <div class="b1tit">Reaction Condition</div>
          <div class="conbox">
             <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(138,143,183);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:80px;
                        white-space: nowrap;
                        text-align: center;"
                >Solvents
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu  class="project-dropdown"   slot="dropdown" >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="THF"
                            >THF</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="CH2Cl2"
                            >CH<sub>2</sub>Cl<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="14Dioxane"
                            >1,4-Dioxane</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="CHCl3"
                            >CHCl<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="PhMe"
                            >PhMe</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="MeCN"
                            >MeCN</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="EtOAc"
                            >EtOAc</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DMF"
                            >DMF</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="CCl4"
                            >CCl<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="EtOH"
                            >EtOH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="MeOH"
                            >MeOH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="tAmOH"
                            ><i>t</i>AmOH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="tBuOH"
                            ><i>t</i>BuOH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C2H4Cl2"
                            >C<sub>2</sub>H<sub>4</sub>Cl<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Mesitylene"
                            >Mesitylene</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="MTBE"
                            >MTBE</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Hexane"
                            >Hexane</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Furan"
                            >Furan</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DMSO"
                            >DMSO</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Et2O"
                            >Et<sub>2</sub>O</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(186,191,213);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:80px;
                        white-space: nowrap;
                        text-align: center;"
                >Bases
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu  class="project-dropdown"   slot="dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Cs2CO3"
                            >Cs<sub>2</sub>CO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DIPEA"
                            >DIPEA</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="NaOAc"
                            >NaOAc</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="CsOAc"
                            >CsOAc</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Et3N"
                            >Et<sub>3</sub>N</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DBU"
                            >DBU</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DBN"
                            >DBN</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DABCO"
                            >DABCO</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="K2CO3"
                            >K<sub>2</sub>CO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="K3PO4"
                            >K<sub>3</sub>PO<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="KOAc"
                            >KOAc</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="KHCO3"
                            >KHCO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Na2CO3"
                            >Na<sub>2</sub>CO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DMAP"
                            >DMAP</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="TMEDA"
                            >TMEDA</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="NaOBz"
                            >NaOBz</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="LiHMDS"
                            >LiHMDS</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="tBuONa"
                            ><i>t</i>BuONa</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="tBuOK"
                            ><i>t</i>BuOK</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Rb2CO3"
                            >Rb<sub>2</sub>CO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="MTBD"
                            >MTBD</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="nBuLi"
                            >nBuLi</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="NaH"
                            >NaH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="KH"
                            >KH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="KHMDS"
                            >KHMDS</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(138,143,183);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:70px;
                        white-space: nowrap;
                        text-align: center;"
                >Temperature
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu  class="project-dropdown"  slot="dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="-78C"
                            >-78 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="-40C"
                            >-40 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="-25to-20C"
                            >-25 to -20 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="10to-5C"
                            >-10 to -5 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="0C"
                            >0 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="10C"
                            >10 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="rt"
                            >rt</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="30C"
                            >30 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="40C"
                            >40 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="50C"
                            >50 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="60C"
                            >60 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="65C"
                            >65 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="70C"
                            >70 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="80C"
                            >80 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="100C"
                            >100 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="120C"
                            >120 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="130C"
                            >130 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="160C"
                            >160 °C</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(186,191,213);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:70px;
                        white-space: nowrap;
                        text-align: center;"
                >Atmosphere
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="air"
                            >air</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="O2"
                            >O<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N2"
                            >N<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Ar"
                            >Ar</a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(138,143,183);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:70px;
                        white-space: nowrap;
                        text-align: center;"
                >Oxidants
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DQ"
                            >DQ</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="MnO2"
                            >MnO<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="TEMPO"
                            >TEMPO</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="TsCl"
                            >TsCl</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Halogenatedbenzene"
                            >Halogenated benzene</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="O2"
                            >O<sub>2</sub></a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(186,191,213);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:70px;
                        white-space: nowrap;
                        text-align: center;"
                      >Additives
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="MolecularSieves"
                            >Molecular Sieves</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="LiCl"
                            >LiCl</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Mg(OtBu)2"
                            >Mg(O<i>t</i>Bu)<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Sc(OTf)3"
                            >Sc(OTf)<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Mg(OTf)2"
                            >Mg(OTf)<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Cu(OTf)2"
                            >Cu(OTf)<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Zn(OTf)2"
                            >Zn(OTf)<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="BHT"
                            >BHT</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="La(OTf)3"
                            >La(OTf)<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="tBuOLi"
                            ><i>t</i>BuOLi</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Catechol"
                            >Catechol</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="BuCO2H"
                            >BuCO<sub>2</sub>H</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="NaBF4"
                            >NaBF<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Ti(OiPr)4"
                            >Ti(O<i>i</i>Pr)<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="2-ClPhCO2Na"
                            >2-ClPhCO<sub>2</sub>Na</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="HFIP"
                            >HFIP</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="S8"
                            >S<sub>8</sub></a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

          </div>
        </div>
        <div class="mycon">
          <div class="b1tit">NHC-Catalysts</div>
          <div class="conbox">
            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(138,143,183);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:70px;
                        white-space: nowrap;
                        text-align: center;"
                >NHC-types
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Aminoindane-based Triazoliums"
                            >Aminoindane-based Triazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Morpholine-based Triazoliums"
                            >Morpholine-based Triazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Pyrrolidine-based Triazoliums"
                            >Pyrrolidine-based Triazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Acyclic Triazoliums"
                            >Acyclic Triazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Oxazolidine-based Heterazoliums"
                            >Oxazolidine-based Heterazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Imidazoline-based Heterazoliums"
                            >Imidazoline-based Heterazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Imidazole-based Heterazoliums"
                            >Imidazole-based Heterazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Thiazole-based Heterazoliums"
                            >Thiazole-based Heterazoliums</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(186,191,213);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:70px;
                        white-space: nowrap;
                        text-align: center;"
                >N-substituent
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-Ph"
                            >N-Ph</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-4-Cl-C6H4"
                            >N-4-Cl-C6H4</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-Mes"
                            >N-Mes</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-4-OMe-C6H4"
                            >N-4-OMe-C<sub>6</sub>H<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-C6F5"
                            >N-C<sub>6</sub>F<sub>5</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-2,6-Et-C6H3"
                            >N-2,6-Et-C<sub>6</sub>H<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-C6H2Cl3"
                            >N-C<sub>6</sub>H<sub>2</sub>Cl<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-2,6-iPr-C6H3"
                            >N-2,6-<i>i</i>Pr-C<sub>6</sub>H<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-tBu"
                            >N-<i>t</i>Bu</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-2,6-OMe-C6H3"
                            >N-2,6-OMe-C<sub>6</sub>H<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-C6H2Cl3"
                            >N-C<sub>6</sub>H<sub>2</sub>Cl<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-3Cyclic-C6H2"
                            >N-3Cyclic-C<sub>6</sub>H<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-C6H2Br3"
                            >N-C<sub>6</sub>H<sub>2</sub>Br<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-4-CF3-C6H4"
                            >N-4-CF<sub>3</sub>-C<sub>6</sub>H<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-3Me-C6H2"
                            >N-3Me-C<sub>6</sub>H<sub>2</sub></a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(138,143,183);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:70px;
                        white-space: nowrap;
                        text-align: center;"
                >X- of NHC-HX
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="BF4"
                            >BF<sub>4</sub><sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Cl-"
                            >cl<sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="ClO4-"
                            >ClO<sub>4</sub><sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Br-"
                            >Br<sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="OTf-"
                            >OTf<sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="I-"
                            >I<sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="PF6-"
                            >PF<sub>6</sub><sup>-</sup></a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

          </div>
        </div>
        <div class="mycon">
          <div class="b1tit">Reaction Modes</div>
          <div class="conbox">

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(138,143,183);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:60px;
                        white-space: nowrap;
                        text-align: center;"
                >Reaction types
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Benzoin"
                            >Benzoin</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Stetter"
                            >Stetter</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Radical Couplling"
                            >Radical Couplling</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Remote Activation"
                            >Remote Activation</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Esterification"
                            >Esterification</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="B-hydydroxylation"
                            >β-hydydroxylation</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Desymmetrization"
                            >Desymmetrization</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Single Electron Transfer"
                            >Single Electron Transfer</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(186,191,213);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:20px;
                        white-space: nowrap;
                        text-align: center;"               
                        >Annulation Reactions
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[2+4]"
                            >[2+4]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[2+3]"
                            >[2+3]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[3+3]"
                            >[3+3]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[4+3]"
                            >[4+3]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[2+2]"
                            >[2+2]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[2+1]"
                            >[2+1]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[2+2+2]"
                            >[2+2+2]</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(138,143,183);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:40px;
                        white-space: nowrap;
                        text-align: center;"
                >New bond formed
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-N"
                            >C-N</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-C"
                            >C-C</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-O"
                            >C-O</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-S"
                            >C-S</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-P"
                            >C-P</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-B"
                            >C-B</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-Si"
                            >C-Si</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-F"
                            >C-F</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-N"
                            >N-N</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(186,191,213);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:50px;
                        white-space: nowrap;
                        text-align: center;"
                >Substrate types
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Aryl aldehyde"
                            >Aryl aldehyde</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Alkyl aldehyde "
                            >Alkyl aldehyde </a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Enal"
                            >Enal</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Chorloaldehyde"
                            >Chorloaldehyde</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Dienyl aldehyde"
                            >Dienyl aldehyde</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Cyclic aldehyde or aza-cyclic aldehyde"
                            >Cyclic aldehyde or aza-cyclic aldehyde</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="B-methyl cinnamic acid"
                            >β-methyl cinnamic acid</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="A,B-unsaturated acyl chloride"
                            >α,β-unsaturated acyl chloride</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="A-bromoenal"
                            >α-bromoenal</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="B-methyllenal"
                            >β-methyllenal</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="B-formylenal"
                            >β-formylenal</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(138,143,183);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:70px;
                        white-space: nowrap;
                        text-align: center;"
                      >Chiral type
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Carbon centered chirality"
                            >Carbon centered chirality</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Sulfur center chirality"
                            >Sulfur center chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Phosphorus center chirality"
                            >Phosphorus center chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Nitrogen center chirality"
                            >Nitrogen center chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-C Axial chirality"
                            >C-C Axial chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-N Axial chirality"
                            >C-N Axial chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-N Axial chirality"
                            >N-N Axial chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Plananr chirality"
                            >Plananr chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Spiral Chirality"
                            >Spiral Chirality</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(186,191,213);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:50px;
                        white-space: nowrap;
                        text-align: center;"
                      >Reactive components
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Intermolecular Reaction"
                            >Intermolecular Reaction</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Intramolecular Reaction"
                            >Intramolecular Reaction</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Multicomponent Reaction"
                            >Multicomponent Reaction</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(138,143,183);
                        color: white;
                        font-size: 12px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:6px;
                        white-space: nowrap;
                        text-align: center;"
                      >Key catalyitic intermediates<i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Breslow"
                            >Breslow</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Homoenolate"
                            >Homoenolate</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Enolate"
                            >Enolate</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Acyl Azolium"
                            >Acyl Azolium</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Dienolate"
                            >Dienolate</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="A,B-Unsaturated Acylazoliums"
                            >α,β-Unsaturated Acylazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="NHC-bound Radical Intermediate"
                            >NHC-bound Radical Intermediate</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Dienenyl Acylazolium Intermediat"
                            >Dienenyl Acylazolium Intermediat</a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(186,191,213);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:20px;
                        white-space: nowrap;
                        text-align: center;"
                      >Reaction mechanism
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="KR"
                            >KR</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DKR"
                            >DKR</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Desymmetrization"
                            >Desymmetrization</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Esterification"
                            >Esterification</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Cyclization reaction (Aromatization reaction)"
                            >Cyclization reaction (Aromatization reaction)</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Dearomatization"
                            >Dearomatization</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="item">
              <!-- <div class="text"> -->
                <div class="">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                        background-color: rgb(138,143,183);
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        position: relative;
                        width: 110px;
                        margin-left:10px;
                        white-space: nowrap;
                        text-align: center;"
                        >Free radical generation mode
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Photosensitizer"
                            >Photosensitizer</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Halogenated benzene"
                            >Halogenated benzene</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Strong alkali"
                            >Strong alkali</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Photocatalysis"
                            >Photocatalysis</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-show="this.searchBox2All"
      class="result"
      style="margin-left: 15%; margin-right: 15%"
    >
      <div style="">Reactions: "{{ this.smiles }}"</div>
      <div style="">total {{ this.OrigntableData.length }} results</div>

      <el-table
        :data="
          OrigntableData.slice(
            (currentPage - 1) * pagesize,
            currentPage * pagesize
          )
        "
        border
        style="width: 100%"
      >
        <el-table-column
          label="Entries"
          header-class-name="header-class"
          cell-class-name="cell-class"
          flex="1"
          class="header-class"
          align="center"
        >
          <template v-slot="{ $index }">
            {{ $index + 1 + (currentPage - 1) * pagesize }}
          </template>
        </el-table-column>

        <el-table-column
          prop="Reactions"
          label="Reactions"
          flex="1"
          class="header-class"
          align="center"
        >
          <template slot-scope="scope">
            <img
              :src="
                '/api/pre/' +
                scope.row['basereaction']
                  .replace('.tif', '.png')
                  .replace(' ', '')
              "
              @click="
                EnterPaperDetailType = 1;
                lookDetails(
                  1,
                  currentPage,
                  pagesize,
                  scope.row,
                  scope.$index + (currentPage - 1) * pagesize
                );
              "
              alt="-"
              class="image-hover-effect"
              style="width: 50%"
            />
          </template>
        </el-table-column>
      </el-table>

      <div style="display: flex; justify-content: center">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[1, 2, 3, 4]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="OrigntableData.length"
        >
        </el-pagination>
      </div>

      <div style="height: 100px"></div>
      <div style="">References: "{{ this.smiles }}"</div>
      <div style="">total {{ this.OrigntableData.length }} results</div>

      <el-table
        :data="
          OrigntableData.slice(
            (currentPage2 - 1) * pagesize2,
            currentPage2 * pagesize2
          )
        "
        border
        style="width: 100%"
      >
        <el-table-column
          label="Entries"
          header-class-name="header-class"
          cell-class-name="cell-class"
          flex="1"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.$index + 1 + (currentPage2 - 1) * pagesize2 }}
          </template>
        </el-table-column>

        <el-table-column prop="Titles" label="Titles" align="center">
          <template slot-scope="scope">
            <div
              @click="
                EnterPaperDetailType = 1;
                lookDetails(
                  2,
                  currentPage2,
                  pagesize2,
                  scope.row,
                  scope.$index + (currentPage2 - 1) * pagesize2
                );
              "
              v-html="formatTitle(scope.row.Titles)"
              class="image-hover-effect"
            ></div>
          </template>
        </el-table-column>

        <el-table-column prop="Journals" label="Journals" align="center">
        </el-table-column>

        <el-table-column prop="DOI" label="DOI" width="180" align="center">
        </el-table-column>

        <el-table-column
          prop="Authors"
          label="Authors"
          width="280"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="PublishTime" label="Publish Time" align="center">
          <template slot-scope="scope">
            <div v-html="formatDate(scope.row.PublishTime)"></div>
          </template>
        </el-table-column>
      </el-table>

      <div style="display: flex; justify-content: center">
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="currentPage2"
          :page-sizes="[1, 2, 3, 4]"
          :page-size="pagesize2"
          layout="total, sizes, prev, pager, next, jumper"
          :total="OrigntableData.length"
        >
        </el-pagination>
      </div>
    </div>

    <div
      v-show="this.searchBox2Table"
      class="result"
      style="margin-left: 15%; margin-right: 15%"
    >
      <div style="">References: "{{ this.smiles }}"</div>
      <div style="">total {{ this.OrigntableData.length }} results</div>

      <el-table
        :data="
          OrigntableData.slice(
            (currentPage2 - 1) * pagesize2,
            currentPage2 * pagesize2
          )
        "
        border
        style="width: 100%"
      >
        <el-table-column
          label="Entries"
          header-class-name="header-class"
          cell-class-name="cell-class"
          flex="1"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.$index + 1 + (currentPage2 - 1) * pagesize2 }}
          </template>
        </el-table-column>

        <el-table-column prop="Titles" label="Titles" align="center">
          <template slot-scope="scope">
            <div
              @click="
                EnterPaperDetailType = 4;
                lookDetails(
                  2,
                  currentPage2,
                  pagesize2,
                  scope.row,
                  scope.$index + (currentPage2 - 1) * pagesize2
                );
              "
              v-html="formatTitle(scope.row.Titles)"
              class="image-hover-effect"
            ></div>
          </template>
        </el-table-column>

        <el-table-column prop="Journals" label="Journals" align="center">
        </el-table-column>

        <el-table-column prop="DOI" label="DOI" width="180" align="center">
        </el-table-column>

        <el-table-column
          prop="Authors"
          label="Authors"
          width="280"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="PublishTime" label="Publish Time" align="center">
          <template slot-scope="scope">
            <div v-html="formatDate(scope.row.PublishTime)"></div>
          </template>
        </el-table-column>
      </el-table>

      <div style="display: flex; justify-content: center">
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="currentPage2"
          :page-sizes="[1, 2, 3, 4]"
          :page-size="pagesize2"
          layout="total, sizes, prev, pager, next, jumper"
          :total="OrigntableData.length"
        >
        </el-pagination>
      </div>
    </div>

    <div
      v-show="this.searchBox2Pic"
      class="result"
      style="margin-left: 15%; margin-right: 15%"
    >
      <div style="">Reactions: "{{ this.smiles }}"</div>
      <div style="">total {{ this.OrigntableData.length }} results</div>

      <el-table
        :data="
          OrigntableData.slice(
            (currentPage - 1) * pagesize,
            currentPage * pagesize
          )
        "
        border
        style="width: 100%"
      >
        <el-table-column
          label="Entries"
          header-class-name="header-class"
          cell-class-name="cell-class"
          flex="1"
          class="header-class"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.$index + 1 + (currentPage - 1) * pagesize }}
          </template>
        </el-table-column>
        <el-table-column
          prop="Reactions"
          label="Reactions"
          flex="1"
          class="header-class"
          align="center"
        >
          <template slot-scope="scope">
            <img
              :src="
                '/api/pre/' +
                scope.row['basereaction']
                  .replace('.tif', '.png')
                  .replace(' ', '')
              "
              @click="
                EnterPaperDetailType = 3;
                lookDetails(
                  1,
                  currentPage,
                  pagesize,
                  scope.row,
                  scope.$index + (currentPage - 1) * pagesize
                );
              "
              alt="-"
              class="image-hover-effect"
              style="width: 50%"
            />
          </template>
        </el-table-column>
      </el-table>

      <div style="display: flex; justify-content: center">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[1, 2, 3, 4]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="OrigntableData.length"
        >
        </el-pagination>
      </div>
    </div>

    <div v-show="this.browseShow" class="browse" style="font-weight: bold">
      <img
        src="./assets/pic/background1.png"
        style="
          display: block;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 0px;
          width: 100vw;
        "
      />
      <div style="margin-left: 15%; margin-top: 10px">
        <span
          style="
            background-color: rgb(214, 79, 56);
            font-weight: bold;
            padding: 3px;
            color: white;
          "
          >Reactions</span
        >
        <div></div>

        <div style="margin-top: 15px">
          <div style="display: inline-block; float: left; margin-left: 80px">
            <span style="background-color: rgb(214, 216, 230); color: black">
              <el-row sytle="display: inline;">
                <el-col sytle="display: inline;">
                  <el-dropdown sytle="display: inline;">
                    <span
                      class="el-dropdown-link"
                      style="
                        background-color: rgb(214, 216, 230);
                        padding: 4px;
                        color: black;
                      "
                    >
                      Annulation<i
                        class="el-icon-arrow-down el-icon--right"
                      ></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="project-dropdown">
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="[4+2] Cycloaddition"
                          >[4+2] Cycloaddition</a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a
                          @click="showBrowseDetail"
                          name="[2+2+2] Cycloaddition"
                          >[2+2+2] Cycloaddition</a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="[2+2] Cycloaddition"
                          >[2+2] Cycloaddition</a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="[4+3] Cycloaddition"
                          >[4+3] Cycloaddition</a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="[3+2] Cycloaddition"
                          >[3+2] Cycloaddition</a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="[3+3] Cycloaddition"
                          >[3+3] Cycloaddition</a
                        ></el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </span>
          </div>
          <div>
            <span
              style="
                background-color: rgb(214, 216, 230);
                padding: 4px;
                margin-left: 40px;
                color: black;
              "
              ><a
                @click="showBrowseDetail"
                name="Oxidation Acylation"
                style="color: black"
                >Oxidation Acylation</a
              >
            </span>
            <span
              style="
                background-color: rgb(214, 216, 230);
                padding: 4px;
                margin-left: 40px;
                color: black;
              "
              ><a
                @click="showBrowseDetail"
                name="Umpolung Catalysis"
                style="color: black"
                >Umpolung Catalysis</a
              >
            </span>
            <span
              style="
                background-color: rgb(214, 216, 230);
                padding: 4px;
                margin-left: 40px;
                color: black;
              "
              ><a
                @click="showBrowseDetail"
                name="NHC-M Catalysis"
                style="color: black"
                >NHC-M Catalysis</a
              ></span
            >
            <span
              style="
                background-color: rgb(214, 216, 230);
                padding: 4px;
                color: black;
                margin-left: 40px;
              "
              ><a
                @click="showBrowseDetail"
                name="Visible light-NHC Catalysis"
                style="color: black"
                >Visible light-NHC Catalysis</a
              ></span
            >
          </div>
        </div>

        <div class="up">
          <div class="b1" style="margin-top: 15px; margin-left: 80px"></div>
          <div class="b2" style="margin-top: 15px; margin-left: 80px"></div>
          <div class="b3" style="margin-top: 15px; margin-left: 80px"></div>
          <div class="b4" style="margin-top: 15px; margin-left: 80px"></div>
          <div class="b7" style="margin-top: 15px; margin-left: 80px"></div>
          <div
            class="b8"
            style="margin-top: 15px; margin-bottom: 15px; margin-left: 80px"
          ></div>
        </div>

        <span
          style="
            background-color: rgb(214, 79, 56);
            font-weight: bold;
            padding: 3px;
            color: white;
          "
          >Catalysts</span
        >
        <div></div>
        <div class="down" style="margin-top: 15px">
          <span
            style="
              background-color: rgb(214, 216, 230);
              padding: 4px;
              margin-left: 80px;
              color: black;
            "
            ><a @click="showBrowseDetail" name="Thiazoles" style="color: black"
              >Thiazoles</a
            >
          </span>
          <span
            style="
              background-color: rgb(214, 216, 230);
              padding: 4px;
              margin-left: 40px;
              color: black;
            "
            ><a @click="showBrowseDetail" name="Imidazoles" style="color: black"
              >Imidazoles</a
            >
          </span>
          <span
            style="
              background-color: rgb(214, 216, 230);
              padding: 4px;
              margin-left: 40px;
              color: black;
            "
            ><a
              @click="showBrowseDetail"
              name="Imidazolines"
              style="color: black"
              >Imidazolines</a
            >
          </span>
          <span
            style="
              background-color: rgb(214, 216, 230);
              padding: 4px;
              margin-left: 40px;
              color: black;
            "
            ><a @click="showBrowseDetail" name="Triazoles" style="color: black"
              >Triazoles</a
            >
          </span>
        </div>

        <span
          style="
            margin-top: 15px;
            background-color: rgb(214, 79, 56);
            font-weight: bold;
            padding: 3px;
            color: white;
          "
          >Base</span
        >
        <div></div>
        <div class="up">
          <div class="b3" style="margin-top: 15px; margin-left: 80px"></div>
          <div class="b4" style="margin-top: 15px; margin-left: 80px"></div>
          <div
            class="b5"
            style="margin-left: 80px; float: left; margin-right: 40px"
          >
            <span style="background-color: rgb(214, 216, 230); color: black">
              <el-row class="block-col-2">
                <el-col>
                  <el-dropdown>
                    <span
                      class="el-dropdown-link"
                      style="
                        background-color: rgb(214, 216, 230);
                        padding: 4px;
                        color: black;
                      "
                    >
                      inorganic base<i
                        class="el-icon-arrow-down el-icon--right"
                      ></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="project-dropdown">
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="CS2CO3"
                          >Cs<sub>2</sub>CO<sub>3</sub></a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="NA2CO3"
                          >Na<sub>2</sub>CO<sub>3</sub></a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="K2CO3"
                          >K<sub>2</sub>CO<sub>3</sub></a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="NAOAC"
                          >NaOAc</a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="KOAC"
                          >KOAc</a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="K2HPO4"
                          >K<sub>2</sub>HPO<sub>4</sub></a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="K3PO4"
                          >K<sub>3</sub>PO<sub>4</sub></a
                        ></el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </span>
          </div>
          <div class="b5" style="display: inline-block">
            <span style="background-color: rgb(214, 216, 230); color: black">
              <el-row class="block-col-2">
                <el-col>
                  <el-dropdown>
                    <span
                      class="el-dropdown-link"
                      style="
                        background-color: rgb(214, 216, 230);
                        padding: 4px;
                        color: black;
                      "
                    >
                      organic base<i
                        class="el-icon-arrow-down el-icon--right"
                      ></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="project-dropdown">
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="NET3"
                          >NEt<sub>3</sub></a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="DBU"
                          >DBU</a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="DABCO"
                          >DABCO</a
                        ></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><a @click="showBrowseDetail" name="DIPEA"
                          >DIPEA</a
                        ></el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </span>
          </div>
        </div>

        <span
          style="
            background-color: rgb(214, 79, 56);
            font-weight: bold;
            padding: 3px;
            color: white;
          "
          >Solvent</span
        >
        <div></div>
        <div class="up">
          <div class="b1" style="margin-top: 15px; margin-left: 80px">
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="CH2Cl2"
                >CH<sub>2</sub>Cl<sub>2</sub></a
              ></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="CHCl3"
                >CHCl<sub>3</sub></a
              ></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="CCl4"
                >CCl<sub>4</sub></a
              ></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="DCE">DCE</a></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="DME">DME</a></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="DMF">DMF</a></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="EtOAc">EtOAc</a></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="EtOH">EtOH</a></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="Et2O"
                >Et<sub>2</sub>O</a
              ></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="Furan">Furan</a></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="H2O">H<sub>2</sub>O</a></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="HFIP">HFIP</a></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="MeCN">MeCN</a></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="MeOH">MeOH</a></span
            >
          </div>
          <div class="b1" style="margin-top: 15px; margin-left: 80px">
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="PhCF3">PhCF</a
              ><sub>3</sub></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="THF">THF</a></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="Toluene">Toluene</a></span
            >
            <span style="padding: 4px; margin-right: 60px"
              ><a @click="showBrowseDetail" name="1,4-dioxane"
                >1,4-dioxane</a
              ></span
            >
          </div>
        </div>

        <span
          style="
            background-color: rgb(214, 79, 56);
            font-weight: bold;
            padding: 3px;
            color: white;
          "
          >Addition</span
        >
        <div></div>
        <div class="up">
          <div class="b1" style="margin-top: 15px; margin-left: 80px">
            <span
              style="
                padding: 4px;
                margin-right: 60px;
                background-color: rgb(214, 216, 230);
                color: black;
              "
              ><a @click="showBrowseDetail" name="Oxidants" style="color: black"
                >Oxidants</a
              ></span
            >
            <span
              style="
                padding: 4px;
                margin-right: 60px;
                background-color: rgb(214, 216, 230);
                color: black;
              "
              ><a
                @click="showBrowseDetail"
                name="Assistant"
                style="color: black"
                >Assistant</a
              ></span
            >
            <span
              style="
                padding: 4px;
                margin-right: 60px;
                background-color: rgb(214, 216, 230);
                color: black;
              "
              ><a @click="showBrowseDetail" name="HV" style="color: black"
                >h&nu;</a
              ></span
            >
          </div>
        </div>
      </div>
    </div>
    <div v-show="this.browseShow1">
      <img
        src="./assets/pic/background1.png"
        style="
          display: block;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 0px;
          width: 100vw;
        "
      />
      <div class="bbb1">
        <div class="b1tit">Reaction Conditions</div>
        <div class="concon">
          <!-- Solvents -->
          <div class="list">
            <div class="qiu">Solvents</div>
            <div class="listbox">
              <div class="item"><a @click="showBrowseDetail" name="THF">THF</a></div>
              <div class="item"><a @click="showBrowseDetail" name="CH2Cl2">CH<sub>2</sub>Cl<sub>2</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="14Dioxane">1,4-Dioxane</a></div>
              <div class="item"><a @click="showBrowseDetail" name="CHCl3">CHCl<sub>3</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="PhMe">PhMe</a></div>
              <div class="item"><a @click="showBrowseDetail" name="MeCN">MeCN</a></div>
              <div class="item"><a @click="showBrowseDetail" name="EtOAc">EtOAc</a></div>
              <div class="item"><a @click="showBrowseDetail" name="DMF">DMF</a></div>
              <div class="item"><a @click="showBrowseDetail" name="CCl4">CCl<sub>4</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="EtOH">EtOH</a></div>
              <div class="item"><a @click="showBrowseDetail" name="MeOH">MeOH</a></div>
              <div class="item"><a @click="showBrowseDetail" name="tAmOH"><i>t</i>AmOH</a></div>
              <div class="item"><a @click="showBrowseDetail" name="tBuOH"><i>t</i>BuOH</a></div>
              <div class="item"><a @click="showBrowseDetail" name="C2H4Cl2">C<sub>2</sub>H<sub>4</sub>Cl<sub>2</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="Mesitylene">Mesitylene</a></div>
              <div class="item"><a @click="showBrowseDetail" name="MTBE">MTBE</a></div>
              <div class="item"><a @click="showBrowseDetail" name="Hexane">Hexane</a></div>
              <div class="item"><a @click="showBrowseDetail" name="Furan">Furan</a></div>
              <div class="item"><a @click="showBrowseDetail" name="DMSO">DMSO</a></div>
              <div class="item"><a @click="showBrowseDetail" name="Et2O">Et<sub>2</sub>O</a></div>
            </div>
          </div>

          <!-- Bases -->
          <div class="list">
            <div class="qiu">Bases</div>
            <div class="listbox">
              <div class="item"><a @click="showBrowseDetail" name="Cs2CO3">Cs<sub>2</sub>CO<sub>3</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="DIPEA">DIPEA</a></div>
              <div class="item"><a @click="showBrowseDetail" name="NaOAc">NaOAc</a></div>
              <div class="item"><a @click="showBrowseDetail" name="CsOAc">CsOAc</a></div>
              <div class="item"><a @click="showBrowseDetail" name="Et3N">Et<sub>3</sub>N</a></div>
              <div class="item"><a @click="showBrowseDetail" name="DBU">DBU</a></div>
              <div class="item"><a @click="showBrowseDetail" name="DBN">DBN</a></div>
              <div class="item"><a @click="showBrowseDetail" name="DABCO">DABCO</a></div>
              <div class="item"><a @click="showBrowseDetail" name="K2CO3">K<sub>2</sub>CO<sub>3</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="K3PO4">K<sub>3</sub>PO<sub>4</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="KOAc">KOAc</a></div>
              <div class="item"><a @click="showBrowseDetail" name="KHCO3">KHCO<sub>3</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="Na2CO3">Na<sub>2</sub>CO<sub>3</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="DMAP">DMAP</a></div>
              <div class="item"><a @click="showBrowseDetail" name="TMEDA">TMEDA</a></div>
              <div class="item"><a @click="showBrowseDetail" name="NaOBz">NaOBz</a></div>
              <div class="item"><a @click="showBrowseDetail" name="LiHMDS">LiHMDS</a></div>
              <div class="item"><a @click="showBrowseDetail" name="tBuONa"><i>t</i>BuONa</a></div>
              <div class="item"><a @click="showBrowseDetail" name="tBuOK"><i>t</i>BuOK</a></div>
              <div class="item"><a @click="showBrowseDetail" name="Rb2CO3">Rb<sub>2</sub>CO<sub>3</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="MTBD">MTBD</a></div>
              <div class="item"><a @click="showBrowseDetail" name="nBuLi">nBuLi</a></div>
              <div class="item"><a @click="showBrowseDetail" name="NaH">NaH</a></div>
              <div class="item"><a @click="showBrowseDetail" name="KH">KH</a></div>
              <div class="item"><a @click="showBrowseDetail" name="KHMDS">KHMDS</a></div>
            </div>
          </div>

          <!-- Temperature -->
          <div class="list">
            <div class="qiu">Tempe- rature</div>
            <div class="listbox">
              <div class="item"><a @click="showBrowseDetail" name="-78C">-78 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="-40C">-40 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="-25to-20C">-25 to -20 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="10to-5C">-10 to -5 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="0C">0 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="10C">10 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="rt">rt</a></div>
              <div class="item"><a @click="showBrowseDetail" name="30C">30 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="40C">40 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="50C">50 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="60C">60 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="65C">65 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="70C">70 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="80C">80 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="100C">100 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="120C">120 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="130C">130 °C</a></div>
              <div class="item"><a @click="showBrowseDetail" name="160C">160 °C</a></div>
            </div>
          </div>

          <!-- Atmosphere-->
          <div class="list">
            <div class="qiu">Atmos- phere</div>
            <div class="listbox">
              <div class="item"><a @click="showBrowseDetail" name="air">air</a></div>
              <div class="item"><a @click="showBrowseDetail" name="O2">O<sub>2</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="N2">N<sub>2</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="Ar">Ar</a></div>
            </div>
          </div>

          <!-- Oxidants -->
          <div class="list">
            <div class="qiu">Oxidants</div>
            <div class="listbox">
              <div class="item"><a @click="showBrowseDetail" name="DQ">DQ</a></div>
              <div class="item"><a @click="showBrowseDetail" name="MnO2">MnO<sub>2</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="TEMPO">TEMPO</a></div>
              <div class="item"><a @click="showBrowseDetail" name="TsCl">TsCl</a></div>
              <div class="item"><a @click="showBrowseDetail" name="Halogenatedbenzene">Halogenated benzene</a></div>
              <div class="item"><a @click="showBrowseDetail" name="O2">O<sub>2</sub></a></div>
            </div>
          </div>

          <!-- Additives -->
          <div class="list">
            <div class="qiu">Additives</div>
            <div class="listbox">
              <div class="item"><a @click="showBrowseDetail" name="MolecularSieves">Molecular Sieves</a></div>
              <div class="item"><a @click="showBrowseDetail" name="LiCl">LiCl</a></div>
              <div class="item"><a @click="showBrowseDetail" name="Mg(OtBu)2">Mg(O<i>t</i>Bu)<sub>2</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="Sc(OTf)3">Sc(OTf)<sub>3</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="Mg(OTf)2">Mg(OTf)<sub>2</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="Cu(OTf)2">Cu(OTf)<sub>2</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="Zn(OTf)2">Zn(OTf)<sub>2</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="BHT">BHT</a></div>
              <div class="item"><a @click="showBrowseDetail" name="La(OTf)3">La(OTf)<sub>3</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="tBuOLi"><i>t</i>BuOLi</a></div>
              <div class="item"><a @click="showBrowseDetail" name="Catechol">Catechol</a></div>
              <div class="item"><a @click="showBrowseDetail" name="BuCO2H">BuCO<sub>2</sub>H</a></div>
              <div class="item"><a @click="showBrowseDetail" name="NaBF4">NaBF<sub>4</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="Ti(OiPr)4">Ti(O<i>i</i>Pr)<sub>4</sub></a></div>
              <div class="item"><a @click="showBrowseDetail" name="2-ClPhCO2Na">2-ClPhCO<sub>2</sub>Na</a></div>
              <div class="item"><a @click="showBrowseDetail" name="HFIP">HFIP</a></div>
              <div class="item"><a @click="showBrowseDetail" name="S8">S<sub>8</sub></a></div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div v-show="this.browseShow2">
      <!-- 树状展开的那个导航栏 -->
      <img
        src="./assets/pic/background1.png"
        style="
          display: block;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 0px;
          width: 100vw;
        "
      />
      <div class="bb1">
        <div class="b1tit">NHC-Catalysts</div>
        <img src="./assets/img/2.jpg" alt="" class="" />
        <div class="bb1con">
          <div class="left left1">
            <img src="./assets/img/1.jpg" alt="" class="" />
            <div class="smtt">NHC types</div>
            <div class="bbcon">
              <!-- 下面mybbox后面的style让中间的那两个标签在水平和垂直方向上居中对齐 -->
              <div class="mybbbox" style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                <img src="./assets/img/types1.png" alt="" />
                <div class="smtext"><a @click="showBrowseDetail" name="Aminoindane-based Triazoliums">Aminoindane-based Triazoliums</a></div>
              </div>
              <div class="mybbbox" style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                <img src="./assets/img/types2.png" alt="" />
                <div class="smtext"><a @click="showBrowseDetail" name="Morpholine-based Triazoliums">Morpholine-based Triazoliums</a></div>
              </div>
              <div class="mybbbox" style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                <img src="./assets/img/types3.png" alt="" />
                <div class="smtext"><a @click="showBrowseDetail" name="Pyrrolidine-based Triazoliums">Pyrrolidine-based Triazoliums</a></div>
              </div>
              <div class="mybbbox" style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                <img src="./assets/img/types4.png" alt="" />
                <div class="smtext"><a @click="showBrowseDetail" name="Acyclic Triazoliums">Acyclic Triazoliums</a></div>
              </div>
              <div class="mybbbox" style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                  <img src="./assets/img/types5.png" alt="" />
                  <div class="smtext"><a @click="showBrowseDetail" name="Oxazolidine-based Heterazoliums">Oxazolidine-based Heterazoliums</a></div>
              </div>
              <div class="mybbbox" style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                <img src="./assets/img/types6.png" alt="" />
                <div class="smtext"><a @click="showBrowseDetail" name="Imidazoline-based Heterazoliums">Imidazoline-based Heterazoliums</a></div>
              </div><div class="mybbbox" style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                <img src="./assets/img/types7.png" alt="" />
                <div class="smtext"><a @click="showBrowseDetail" name="Imidazole-based Heterazoliums">Imidazole-based Heterazoliums</a></div>
              </div>
              <div class="mybbbox" style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
                <img src="./assets/img/types8.png" alt="" />
                <div class="smtext"><a @click="showBrowseDetail" name="Thiazole-based Heterazoliums">Thiazole-based Heterazoliums</a></div>
              </div>
            </div>
          </div>
          <div class="left left2">
            <img src="./assets/img/3.jpg" alt="" class="" />
            <div class="smtt">N-substituent</div>
            <div class="bbcon">
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-Ph">N-Ph</a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-4-Cl-C6H4">N-4-Cl-C6H4</a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-Mes">N-Mes</a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-4-OMe-C6H4">N-4-OMe-C<sub>6</sub>H<sub>4</sub></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-C6F5">N-C<sub>6</sub>F<sub>5</sub></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-2,6-Et-C6H3">N-2,6-Et-C<sub>6</sub>H<sub>3</sub></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-C6H2Cl3">N-C<sub>6</sub>H<sub>2</sub>Cl<sub>3</sub></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-2,6-iPr-C6H3">N-2,6-<i>i</i>Pr-C<sub>6</sub>H<sub>3</sub></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-tBu">N-<i>t</i>Bu</a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-2,6-OMe-C6H3">N-2,6-OMe-C<sub>6</sub>H<sub>3</sub></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-C6H2Cl3">N-C<sub>6</sub>H<sub>2</sub>Cl<sub>3</sub></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-3Cyclic-C6H2">N-3Cyclic-C<sub>6</sub>H<sub>2</sub></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-C6H2Br3">N-C<sub>6</sub>H<sub>2</sub>Br<sub>3</sub></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-4-CF3-C6H4">N-4-CF<sub>3</sub>-C<sub>6</sub>H<sub>4</sub></a></div>
              </div>
              <div class="bbbox nobbboxafter"> <!--单独设置一个类来消除bbbox的after-->
                <div class="smtext " style="background-color:rgba(255,255,255,0);"></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="N-3Me-C6H2">N-3Me-C<sub>6</sub>H<sub>2</sub></a></div>
              </div>
            </div>
          </div>
          <div class="left">
            <img src="./assets/img/1.jpg" alt="" class="" />
            <div class="smtt">X- of NHC-HX</div>
            <div class="bbcon">
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="BF4">BF<sub>4</sub><sup>-</sup></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="Cl-">cl<sup>-</sup></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="ClO4-">ClO<sub>4</sub><sup>-</sup></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="Br-">Br<sup>-</sup></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="OTf-">OTf<sup>-</sup></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="I-">I<sup>-</sup></a></div>
              </div>
              <div class="bbbox">
                <div class="smtext"><a @click="showBrowseDetail" name="PF6-">PF<sub>6</sub><sup>-</sup></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="this.browseShow3">
      <!--S型的那个导航栏-->
      <img
        src="./assets/pic/background1.png"
        style="
          display: block;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 0px;
          width: 100vw;
        "
      />
      <div class="bbb2">
        <div class="b1tit">Reaction Modes</div>
        <div class="concon">
          <div class="list">
            <div class="sj"> <!--sj是那个菱形方块-->
              <!-- <img src="./assets/img/5.jpg" alt="" /> -->
              <img src="./assets/img/6-r-first.png" alt="" />
              <div class="tt">Reaction Types</div>
            </div>
            <div class="box">
              <div class="item"><a @click="showBrowseDetail" name="Benzoin">Benzoin</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Stetter">Stetter</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Radical Couplling">Radical Couplling</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Remote Activation">Remote Activation</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Esterification">Esterification</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="B-hydydroxylation">β-hydydroxylation</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Desymmetrization">Desymmetrization</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Single Electron Transfer">Single Electron Transfer</a></div>
              <span> / </span>
            </div>
          </div>

          <div class="list">
            <div class="sj">
              <!-- <img src="./assets/img/5.jpg" alt="" /> -->
              <img src="./assets/img/6-l.png" alt="" />
              <div class="tt">Annulation Reactions</div>
            </div>
            <div class="box">
              <div class="item"><a @click="showBrowseDetail" name="[2+4]">[2+4]</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="[2+3]">[2+3]</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="[3+3]">[3+3]</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="[4+3]">[4+3]</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="[2+2]">[2+2]</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="[2+1]">[2+1]</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="[2+2+2]">[2+2+2]</a></div>
            </div>
          </div>


          <div class="list">
            <div class="sj">
              <!-- <img src="./assets/img/5.jpg" alt="" /> -->
              <img src="./assets/img/6-r.png" alt="" />

              <div class="tt">New Bond Formed</div>
            </div>
            <div class="box">
              <div class="item"><a @click="showBrowseDetail" name="C-N">C-N</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="C-C">C-C</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="C-O">C-O</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="C-S">C-S</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="C-P">C-P</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="C-B">C-B</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="C-Si">C-Si</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="C-F">C-F</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="N-N">N-N</a></div>
            </div>
          </div>

          <div class="list">
            <div class="sj">
              <!-- <img src="./assets/img/5.jpg" alt="" /> -->
              <img src="./assets/img/6-l.png" alt="" />

              <div class="tt">Substrate Types</div>
            </div>
            <div class="box">
              <div class="item"><a @click="showBrowseDetail" name="Aryl aldehyde">Aryl aldehyde</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Alkyl aldehyde">Alkyl aldehyde</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Enal">Enal</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Chorloaldehyde">Chorloaldehyde</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Dienyl aldehyde">Dienyl aldehyde</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Cyclic aldehyde or aza-cyclic aldehyde">Cyclic aldehyde or aza-cyclic aldehyde</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="B-methyl cinnamic acid">β-methyl cinnamic acid</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="A,B-unsaturated acyl chloride">α,β-unsaturated acyl chloride</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="A-bromoenal">α-bromoenal</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="B-methyllenal">β-methyllenal</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="B-formylenal">β-formylenal</a></div>
            </div>
          </div>
          <div class="list">
            <div class="sj">
              <!-- <img src="./assets/img/5.jpg" alt="" /> -->
              <img src="./assets/img/6-r.png" alt="" />

              <div class="tt">Chiral Type</div>
            </div>
            <div class="box">
              <div class="item"><a @click="showBrowseDetail" name="Carbon centered chirality">Carbon centered chirality</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Sulfur center chirality">Sulfur center chirality</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Phosphorus center chirality">Phosphorus center chirality</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Nitrogen center chirality">Nitrogen center chirality</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="C-C Axial chirality">C-C Axial chirality</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="C-N Axial chirality">C-N Axial chirality</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="N-N Axial chirality">N-N Axial chirality</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Plananr chirality">Plananr chirality</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Spiral Chirality">Spiral Chirality</a></div>
            </div>
          </div>
          <div class="list">
            <div class="sj">
              <!-- <img src="./assets/img/5.jpg" alt="" /> -->
              <img src="./assets/img/6-l.png" alt="" />

              <div class="tt">Reactive Components</div>
            </div>
            <div class="box">
              <div class="item"><a @click="showBrowseDetail" name="Intermolecular Reaction">Intermolecular Reaction</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Intramolecular Reaction">Intramolecular Reaction</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Multicomponent Reaction">Multicomponent Reaction</a></div>
            </div>
          </div>
          <div class="list">
            <div class="sj">
              <!-- <img src="./assets/img/5.jpg" alt="" /> -->
              <img src="./assets/img/6-r.png" alt="" />

              <div class="tt">Key Catalyitic Intermediates</div>
            </div>
            <div class="box">
              <div class="item"><a @click="showBrowseDetail" name="Breslow">Breslow</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Homoenolate">Homoenolate</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Enolate">Enolate</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Acyl Azolium">Acyl Azolium</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Dienolate">Dienolate</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="A,B-Unsaturated Acylazoliums">α,β-Unsaturated Acylazoliums</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="NHC-bound Radical Intermediate">NHC-bound Radical Intermediate</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Dienenyl Acylazolium Intermediat">Dienenyl Acylazolium Intermediat</a></div>
            </div>
          </div>
          <div class="list">
            <div class="sj">
              <!-- <img src="./assets/img/5.jpg" alt="" /> -->
              <img src="./assets/img/6-l.png" alt="" />

              <div class="tt">Reaction Mechanism</div>
            </div>
            <div class="box">
              <div class="item"><a @click="showBrowseDetail" name="KR">KR</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="DKR">DKR</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Desymmetrization">Desymmetrization</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Esterification">Esterification</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Cyclization reaction (Aromatization reaction)">Cyclization reaction (Aromatization reaction)</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Dearomatization">Dearomatization</a></div>
            </div>
          </div>
          <div class="list">
            <div class="sj">
              <!-- <img src="./assets/img/5.jpg" alt="" /> -->
              <img src="./assets/img/6-r-last.png" alt="" />

              <div class="tt">Free Radical Generation Mode</div>
            </div>
            <div class="box">
              <div class="item"><a @click="showBrowseDetail" name="Photosensitizer">Photosensitizer</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Halogenated benzene">Halogenated benzene</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Strong alkali">Strong alkali</a></div>
              <span> / </span>
              <div class="item"><a @click="showBrowseDetail" name="Photocatalysis">Photocatalysis</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="this.guideShow" class="guide">
      <div class="guidepage">
        <section class="guidew guidemain">
          <div>
            <div class="chap" id="chap1">
              <div class="title">1. Introduction</div>
              <div class="word">
                <p>
                  With the pursuit of sustainable development, innovation and
                  efficiency in chemistry have become increasingly important and
                  urgent. Especially in the field of organic synthesis,
                  efficient and environmentally friendly catalytic reactions
                  play a crucial role in new drug development, materials
                  science, and energy production. The NHC (N-heterocyclic
                  carbene) Catalytic Reaction Database has been established with
                  the aim of improving the efficiency and environmental
                  friendliness of chemical synthesis by centralizing and
                  optimizing catalytic reaction data. The database consolidates
                  a wide range of information on NHC catalytic reactions,
                  encompassing catalyst structures, reaction conditions, product
                  data, and more. The establishment of the NHC Reaction Database
                  is not only beneficial for advancing research in chemical
                  sciences but also crucial for promoting green chemistry and
                  improving the productivity and sustainability of the chemical
                  industry.
                </p>
              </div>
            </div>

            <div class="chap" id="chap2">
              <div class="title">2. Browse reactions</div>
              <div class="word">
                <p>
                  The browsing page is categorized by reaction type, catalyst
                  structure, reaction conditions, etc., so that researchers can
                  efficiently find and analyze the information they need.
                </p>
              </div>
              <img src="./assets/pic/guide1.png" class="pic" />
              <img src="./assets/pic/guide2.png" class="pic" />
              <img src="./assets/pic/guide3.png" class="pic" />
            </div>

            <div class="chap" id="chap3">
              <div class="title">3. Search reactions</div>
              <div class="word">
                <p>
                  In the search page, we provide two main search methods: (1)
                  Search by keywords (e.g. Dehydrogenative Coupling, smiles,
                  additives, etc.); (2) Draw the chemical structure formula to
                  search. Note: In the Search by Keyword column, you can
                  classify the search by All, Reactions or References.
                </p>
              </div>
              <img src="./assets/pic/guide4.png" class="pic" />
            </div>
            <div class="chap" id="chap4">
              <div class="title">4. Download</div>
              <div class="word">
                <p>
                  In the download page, the user can select the corresponding
                  category to check the download of the relevant literature.
                </p>
              </div>
              <img src="./assets/pic/guide5.png" class="pic" />
            </div>

            <div class="chap" id="chap4">
              <div class="title">5. About</div>
              <div class="word">
                <p>
                  In the About page, we provide information about the data
                  source as well as contact information.
                </p>
              </div>
              <img src="./assets/pic/guide6.png" class="pic" />
            </div>
          </div>
        </section>
      </div>
      <div style="display: block; height: 40px"></div>
    </div>

    <div v-show="this.aboutShow" class="about">
      <div class="my3intro my3intro1">
        <div class="my3title"><h1 class="my3h1">About Us</h1></div>
        <div class="my3p-div">
          <ul>
            <li style="margin: -20px">
              <p class="my3p1">
                Develop state-of-art original activation and reaction modes
                enabled by N-heterocyclic carbene as key catalyst, design new
                synthetic strategies for rapid access to functional molecules.
              </p>
            </li>
            <li style="margin: -20px">
              <p class="my3p1">
                Computational chemical biology for the rational design of
                pesticides.
              </p>
            </li>
          </ul>
        </div>

        <div class="my3title"><h1 class="my3h1">Data Sources</h1></div>
        <div class="my3p-div">
          <p class="my3p1 my3center">
            NHC-catalyzed organic reactions from the 1970s to current times.
          </p>
        </div>

        <div class="my3title"><h1 class="my3h1">Reference</h1></div>
        <div class="my3p-div">
          <p class="my3p1">
            [1] Guanjie Wang, Chang Chin Ho, Zhichao Jin, and Yonggui Robin
            Chi*, et. al. Site-Selective C−O Bond Editing of Unprotected
            Saccharides. <strong>J. Am. Chem. Soc.</strong> 2023.
          </p>
          <p class="my3p1">
            [2] Qingyun Wang, Shuquan Wu, Pengcheng Zheng*, Yonggui Robin Chi*,
            et. al. NHC-catalyzed enantioselective access to β-cyano carboxylic
            esters via in situ substrate alternation and release.
            <strong>Nature Commun.</strong> 2023, 14, 4878.
          </p>
          <p class="my3p1">
            [3] Chenghao Tang, Wei Wang, Guoyong Luo, Gefei Hao*, Yonggui Robin
            Chi*, Zhichao Jin*. Carbene-Catalyzed Activation of C−Si Bonds for
            Chemo- and Enantioselective Cross Brook–Benzoin Reaction.
            <strong>Angew. Chem. Int. Ed.</strong> 2022, 61(34): e202206961.
          </p>
          <p class="my3p1">
            [4] Xinyu Dong, Kejun Zhao, Qi Wang*, Xingcai Wu, Yuanqin Huang, Xue
            Wu, Gefei Hao*, et. al. PlantPAD: a platform for large-scale image
            phenomics analysis of disease in plant science.
            <strong>Nucleic Acids Research.</strong> 2023, 52, D1556–D1568.
          </p>
          <p class="my3p1">
            [5] Xingxing Shi, Zhizheng Wang, Fan Wang, Gefei Hao*, Guangfu
            Yang*, ACFIS 2.0: an improved web-server for fragment-based drug
            discovery via a dynamic screening strategy.
            <strong>Nucleic Acids Research.</strong> 2023, 51, W25–W32.
          </p>
        </div>

        <div class="my3title"><h1 class="my3h1">Contact Us</h1></div>
        <div class="my3p-div">
          <p class="my3p1">
            If you have any problems in using it, feel free to contact us.The
            e-mail address is
            <a href="mailto:gefei_hao@foxmail.com">gefei_hao@foxmail.com</a>
          </p>
        </div>
      </div>
    </div>

    <div v-show="show" class="detail detailw" style="padding-left: 5px">
      <div style="display: block; height: 10px"></div>
      <el-button
        @click="returnList"
        style="
          height: 30px;
          line-height: 8px;
          background-color: rgb(236, 245, 255);
          color: rgb(64, 158, 255);
        "
        >back</el-button
      >
      <span style="float: right; padding-top: 5px; padding-right: 10px">
        <div style="display: flex; justify-content: center">
          <img
            src="@/assets/icon/download.svg"
            class="download_icon"
            style="height: 20px"
          />
        </div>
        <div>
          <a :href="this.download" class="download" target="_blank">Download</a>
        </div>
      </span>
      <div></div>
      <div
        style="
          background-color: rgb(214, 79, 56);
          color: white;
          margin-top: 15px;
          font-size: 20px;
          display: inline-block;
        "
        v-html="this.category"
      ></div>
      <div></div>
      <div
        style="
          background-color: rgb(214, 79, 56);
          color: white;
          margin-top: 15px;
          font-size: 20px;
          display: inline-block;
        "
      >
        Entry {{ rownum + 1 }}
      </div>

      <div style="display: block; height: 40px"></div>

      <span
        style="
          color: black;
          font-weight: bold;
          background-color: rgb(237, 237, 237);
          padding: 2px;
        "
        >Title:</span
      >
      <span
        class="Title"
        style="
          color: rgb(0, 32, 96);
          font-weight: bold;
          background-color: rgb(237, 237, 237);
          padding: 2px;
          font-family: 'Times New Roman';
          font-size: 16px;
        "
        >{{ this.t }}</span
      >
      <div></div>

      <strong>Authors:</strong>
      <span
        class="Authors"
        style="font-family: 'Times New Roman'; font-size: 16px"
      >
        {{ this.detailData.authors }}
      </span>
      <div></div>

      <strong>Abstract:</strong>
      <span
        class="Abstract"
        style="font-family: 'Times New Roman'; font-size: 16px"
      >
        {{ this.detailData.abstract }}
      </span>
      <div></div>
      <strong>Keywords:</strong>
      <span
        class="Keywords"
        style="font-family: 'Times New Roman'; font-size: 16px"
      >
        {{ this.detailData.keywords }}
      </span>
      <div></div>
      <div style="display: block; height: 20px"></div>
      <span
        style="
          color: black;
          font-weight: bold;
          background-color: rgb(237, 237, 237);
          padding: 2px;
        "
        >Reaction:</span
      >
      <div></div>

      <div style="display: flex; justify-content: center">
        <img
          style="width: 50vw"
          :src="'/api/pre/' + this.basereaction"
          alt="-"
        />
      </div>
      <div style="display: block; height: 40px"></div>
      <span
        style="
          color: black;
          font-weight: bold;
          background-color: rgb(237, 237, 237);
          padding: 2px;
        "
        >Condition:</span
      >
      <div></div>
      <div class="Condition">
        <div class="result" style="height: 775px; overflow: auto">
          <el-table
            v-if="tableData2.idis187 === 1"
            :data="tableData2"
            border
            style="width: 100%"
            height="100%"
            header-row-class-name="header-row-class"
            header-cell-class-name="header-cell-class"
            :header-row-style="{ position: 'sticky', top: '0' }"
          >
            <el-table-column
              label="Entry"
              header-class-name="header-class"
              cell-class-name="cell-class"
              flex="1"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>

            <el-table-column prop="NHC" label="NHC" flex="1" align="center">
            </el-table-column>

            <el-table-column
              prop="NHC-Structure"
              label="NHC-Structure"
              flex="1"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  <img
                    :src="scope.row['NHC-Structure']"
                    alt="-"
                    style="width: 100%"
                  />
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="Addition-SMILES"
              label="Addition-SMILES"
              flex="1"
              align="center"
            >
            </el-table-column>

            <el-table-column prop="Base" label="Base" flex="1" align="center">
            </el-table-column>

            <el-table-column
              prop="Addition"
              label="Addition"
              flex="1"
              align="center"
            >
            </el-table-column>

            <el-table-column
              prop="Addition-Structure"
              label="Addition-Structure"
              flex="1"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  <img
                    :src="scope.row['Addition-Structure']"
                    alt="-"
                    style="width: 100%"
                  />
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="Addition-SMILES"
              label="Addition-SMILES"
              flex="1"
              align="center"
            >
            </el-table-column>

            <el-table-column
              prop="Solvent"
              label="Solvent"
              flex="1"
              align="center"
            >
            </el-table-column>

            <el-table-column label="T(°C)/Time" flex="1" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.hasOwnProperty('T(°C)/Time')">
                  {{ scope.row["T(°C)/Time"] }}
                </div>
                <div v-else-if="scope.row.hasOwnProperty('Temperature/Time')">
                  {{ scope.row["Temperature/Time"] }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="Site-selectivity(C2:C3:C4:C6)"
              label="Site-selectivity(C2:C3:C4:C6)"
              flex="1"
              align="center"
            >
            </el-table-column>

            <el-table-column
              prop="Yield(%)(C2+C3+C4+C6)"
              label="Yield(%)(C2+C3+C4+C6)"
              flex="1"
              align="center"
            >
            </el-table-column>
          </el-table>
          <el-table
            v-if="tableData2.idis187 === 0"
            :data="tableData2"
            border
            style="width: 100%"
            height="100%"
            header-row-class-name="header-row-class"
            header-cell-class-name="header-cell-class"
            :header-row-style="{ position: 'sticky', top: '0' }"
          >
            <el-table-column
              label="Entry"
              header-class-name="header-class"
              cell-class-name="cell-class"
              flex="1"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>

            <el-table-column
              prop="NHC-Structure"
              label="NHC"
              flex="1"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  <img
                    :src="scope.row['NHC-Structure']"
                    alt="-"
                    style="width: 100%"
                  />
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="Addition"
              label="Additions"
              flex="1"
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.pic === 1">
                  <img :src="scope.row.Addition" alt="-" style="width: 100%" />
                </div>
                <div v-else>
                  {{ scope.row.Addition }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="Base" label="Base" flex="1" align="center">
            </el-table-column>

            <el-table-column
              prop="Solvent"
              label="Solvent"
              flex="1"
              align="center"
            >
            </el-table-column>

            <el-table-column label="T(°C)/Time" flex="1" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.hasOwnProperty('T(°C)/Time')">
                  {{ scope.row["T(°C)/Time"] }}
                </div>
                <div v-else-if="scope.row.hasOwnProperty('Temperature/Time')">
                  {{ scope.row["Temperature/Time"] }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Yield(%)" flex="1" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.hasOwnProperty('Yield(%)')">
                  {{ formatYield(scope.row["Yield(%)"]) }}
                  <!-- {{ scope.row["Yield(%)"] }} -->
                </div>
                <div v-else-if="scope.row.hasOwnProperty('Yield')">
                  {{ formatYield(scope.row["Yield"]) }}
                  <!-- {{ scope.row["Yield"] }} -->
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="ee%" label="ee%" flex="1" align="center">
            </el-table-column>

            <el-table-column prop="dr" label="dr" flex="1" align="center">
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div style="display: block; height: 40px"></div>
      <span
        style="
          color: black;
          font-weight: bold;
          background-color: rgb(237, 237, 237);
          padding: 2px;
        "
        >Products:</span
      >
      <div></div>
      <div class="Products">
        <div class="result" style="height: 988px; overflow: auto">
          <el-table
            :data="arr"
            border
            style="width: 100%"
            height="100%"
            header-row-class-name="header-row-class"
            header-cell-class-name="header-cell-class"
            :header-row-style="{ position: 'sticky', top: '0' }"
          >
            <el-table-column prop="entry" label="Entry" flex="1" align="center">
              <template slot-scope="scope">
                <div>
                  {{ scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="Products"
              label="Products"
              flex="1"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  <img
                    style="width: 100%"
                    :src="'/api/pre/' + scope.row.url"
                    alt="-"
                  />
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Yield(%)" flex="1" align="center">
              <template slot-scope="scope">
                <div>
                  {{ yield2[scope.$index] }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="rr" flex="1" align="center">
              <template slot-scope="scope">
                <div>
                  {{ rr[scope.$index] }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="ee(%)" flex="1" align="center">
              <template slot-scope="scope">
                <div>
                  {{ ee[scope.$index] }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="er" flex="1" align="center">
              <template slot-scope="scope">
                <div>
                  {{ er[scope.$index] }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="dr" flex="1" align="center">
              <template slot-scope="scope">
                <div>
                  {{ dr[scope.$index] }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="E/Z" flex="1" align="center">
              <template slot-scope="scope">
                <div>
                  {{ ez[scope.$index] }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div style="display: block; height: 40px"></div>
      <span
        style="
          color: black;
          font-weight: bold;
          background-color: rgb(237, 237, 237);
          padding: 2px;
        "
        >Mechanism:</span
      >
      <div></div>
      <div style="display: flex; justify-content: center">
        <div class="Mechanism" style="padding-bottom: 30px; width: 600px">
          <img
            style="width: 100%"
            :src="'/api/pre/' + this.imgUrl"
            alt="-"
          />
        </div>
      </div>
    </div>
    <div v-show="browseDetail" class="detail">
      <div>
        <img
          src="./assets/pic/background2.png"
          style="display: block; margin: 0 auto; width: 70%"
        />
      </div>


      <!--browse页面左侧的导航栏-->
      <div
        style="
          background-color: rgb(214, 216, 230);
          width: 25%;
          min-height: 100vh;
          display: inline-block;
          float: left;
          margin-left: 15%;
        "
        class="browseleft"
      >

      
        <strong>Reaction Condition</strong>

        <ul>

          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Solvents
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="THF"
                            >THF</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="CH2Cl2"
                            >CH<sub>2</sub>Cl<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="14Dioxane"
                            >1,4-Dioxane</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="CHCl3"
                            >CHCl<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="PhMe"
                            >PhMe</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="MeCN"
                            >MeCN</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="EtOAc"
                            >EtOAc</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DMF"
                            >DMF</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="CCl4"
                            >CCl<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="EtOH"
                            >EtOH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="MeOH"
                            >MeOH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="tAmOH"
                            ><i>t</i>AmOH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="tBuOH"
                            ><i>t</i>BuOH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C2H4Cl2"
                            >C<sub>2</sub>H<sub>4</sub>Cl<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Mesitylene"
                            >Mesitylene</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="MTBE"
                            >MTBE</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Hexane"
                            >Hexane</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Furan"
                            >Furan</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DMSO"
                            >DMSO</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Et2O"
                            >Et<sub>2</sub>O</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>

          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Bases
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Cs2CO3"
                            >Cs<sub>2</sub>CO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DIPEA"
                            >DIPEA</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="NaOAc"
                            >NaOAc</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="CsOAc"
                            >CsOAc</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Et3N"
                            >Et<sub>3</sub>N</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DBU"
                            >DBU</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DBN"
                            >DBN</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DABCO"
                            >DABCO</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="K2CO3"
                            >K<sub>2</sub>CO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="K3PO4"
                            >K<sub>3</sub>PO<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="KOAc"
                            >KOAc</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="KHCO3"
                            >KHCO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Na2CO3"
                            >Na<sub>2</sub>CO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DMAP"
                            >DMAP</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="TMEDA"
                            >TMEDA</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="NaOBz"
                            >NaOBz</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="LiHMDS"
                            >LiHMDS</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="tBuONa"
                            ><i>t</i>BuONa</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="tBuOK"
                            ><i>t</i>BuOK</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Rb2CO3"
                            >Rb<sub>2</sub>CO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="MTBD"
                            >MTBD</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="nBuLi"
                            >nBuLi</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="NaH"
                            >NaH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="KH"
                            >KH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="KHMDS"
                            >KHMDS</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Temperature
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="-78C"
                            >-78 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="-40C"
                            >-40 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="-25to-20C"
                            >-25 to -20 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="10to-5C"
                            >-10 to -5 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="0C"
                            >0 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="10C"
                            >10 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="rt"
                            >rt</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="30C"
                            >30 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="40C"
                            >40 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="50C"
                            >50 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="60C"
                            >60 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="65C"
                            >65 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="70C"
                            >70 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="80C"
                            >80 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="100C"
                            >100 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="120C"
                            >120 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="130C"
                            >130 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="160C"
                            >160 °C</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Atmosphere
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="air"
                            >air</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="O2"
                            >O<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N2"
                            >N<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Ar"
                            >Ar</a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Oxidants
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DQ"
                            >DQ</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="MnO2"
                            >MnO<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="TEMPO"
                            >TEMPO</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="TsCl"
                            >TsCl</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Halogenatedbenzene"
                            >Halogenated benzene</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="O2"
                            >O<sub>2</sub></a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Additives
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="MolecularSieves"
                            >Molecular Sieves</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="LiCl"
                            >LiCl</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Mg(OtBu)2"
                            >Mg(O<i>t</i>Bu)<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Sc(OTf)3"
                            >Sc(OTf)<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Mg(OTf)2"
                            >Mg(OTf)<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Cu(OTf)2"
                            >Cu(OTf)<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Zn(OTf)2"
                            >Zn(OTf)<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="BHT"
                            >BHT</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="La(OTf)3"
                            >La(OTf)<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="tBuOLi"
                            ><i>t</i>BuOLi</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Catechol"
                            >Catechol</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="BuCO2H"
                            >BuCO<sub>2</sub>H</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="NaBF4"
                            >NaBF<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Ti(OiPr)4"
                            >Ti(O<i>i</i>Pr)<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="2-ClPhCO2Na"
                            >2-ClPhCO<sub>2</sub>Na</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="HFIP"
                            >HFIP</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="S8"
                            >S<sub>8</sub></a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>
        </ul>

      
        <strong>NHC-Catalysts</strong>
        <ul>
          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >NHC-types
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Aminoindane-based Triazoliums"
                            >Aminoindane-based Triazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Morpholine-based Triazoliums"
                            >Morpholine-based Triazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Pyrrolidine-based Triazoliums"
                            >Pyrrolidine-based Triazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Acyclic Triazoliums"
                            >Acyclic Triazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Oxazolidine-based Heterazoliums"
                            >Oxazolidine-based Heterazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Imidazoline-based Heterazoliums"
                            >Imidazoline-based Heterazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Imidazole-based Heterazoliums"
                            >Imidazole-based Heterazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Thiazole-based Heterazoliums"
                            >Thiazole-based Heterazoliums</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>

          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >N-substituent
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-Ph"
                            >N-Ph</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-4-Cl-C6H4"
                            >N-4-Cl-C6H4</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-Mes"
                            >N-Mes</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-4-OMe-C6H4"
                            >N-4-OMe-C<sub>6</sub>H<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-C6F5"
                            >N-C<sub>6</sub>F<sub>5</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-2,6-Et-C6H3"
                            >N-2,6-Et-C<sub>6</sub>H<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-C6H2Cl3"
                            >N-C<sub>6</sub>H<sub>2</sub>Cl<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-2,6-iPr-C6H3"
                            >N-2,6-<i>i</i>Pr-C<sub>6</sub>H<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-tBu"
                            >N-<i>t</i>Bu</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-2,6-OMe-C6H3"
                            >N-2,6-OMe-C<sub>6</sub>H<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-C6H2Cl3"
                            >N-C<sub>6</sub>H<sub>2</sub>Cl<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-3Cyclic-C6H2"
                            >N-3Cyclic-C<sub>6</sub>H<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-C6H2Br3"
                            >N-C<sub>6</sub>H<sub>2</sub>Br<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-4-CF3-C6H4"
                            >N-4-CF<sub>3</sub>-C<sub>6</sub>H<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-3Me-C6H2"
                            >N-3Me-C<sub>6</sub>H<sub>2</sub></a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >X- of NHC-HX
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="BF4"
                            >BF<sub>4</sub><sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Cl-"
                            >cl<sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="ClO4-"
                            >ClO<sub>4</sub><sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Br-"
                            >Br<sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="OTf-"
                            >OTf<sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="I-"
                            >I<sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="PF6-"
                            >PF<sub>6</sub><sup>-</sup></a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>
        </ul>


              
        <strong>Reaction Modes</strong>
        <ul>
          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Reaction types
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Benzoin"
                            >Benzoin</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Stetter"
                            >Stetter</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Radical Couplling"
                            >Radical Couplling</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Remote Activation"
                            >Remote Activation</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Esterification"
                            >Esterification</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="B-hydydroxylation"
                            >β-hydydroxylation</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Desymmetrization"
                            >Desymmetrization</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Single Electron Transfer"
                            >Single Electron Transfer</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>

          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Annulation Reactions
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[2+4]"
                            >[2+4]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[2+3]"
                            >[2+3]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[3+3]"
                            >[3+3]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[4+3]"
                            >[4+3]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[2+2]"
                            >[2+2]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[2+1]"
                            >[2+1]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="[2+2+2]"
                            >[2+2+2]</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >New bond formed
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-N"
                            >C-N</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-C"
                            >C-C</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-O"
                            >C-O</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-S"
                            >C-S</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-P"
                            >C-P</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-B"
                            >C-B</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-Si"
                            >C-Si</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-F"
                            >C-F</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-N"
                            >N-N</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Substrate types
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Aryl aldehyde"
                            >Aryl aldehyde</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Alkyl aldehyde "
                            >Alkyl aldehyde </a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Enal"
                            >Enal</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Chorloaldehyde"
                            >Chorloaldehyde</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Dienyl aldehyde"
                            >Dienyl aldehyde</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Cyclic aldehyde or aza-cyclic aldehyde"
                            >Cyclic aldehyde or aza-cyclic aldehyde</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="B-methyl cinnamic acid"
                            >β-methyl cinnamic acid</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="A,B-unsaturated acyl chloride"
                            >α,β-unsaturated acyl chloride</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="A-bromoenal"
                            >α-bromoenal</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="B-methyllenal"
                            >β-methyllenal</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="B-formylenal"
                            >β-formylenal</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Chiral type
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Carbon centered chirality"
                            >Carbon centered chirality</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Sulfur center chirality"
                            >Sulfur center chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Phosphorus center chirality"
                            >Phosphorus center chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Nitrogen center chirality"
                            >Nitrogen center chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-C Axial chirality"
                            >C-C Axial chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="C-N Axial chirality"
                            >C-N Axial chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="N-N Axial chirality"
                            >N-N Axial chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Plananr chirality"
                            >Plananr chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Spiral Chirality"
                            >Spiral Chirality</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Reactive components
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Intermolecular Reaction"
                            >Intermolecular Reaction</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Intramolecular Reaction"
                            >Intramolecular Reaction</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Multicomponent Reaction"
                            >Multicomponent Reaction</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>




          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Key catalyitic intermediates
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Breslow"
                            >Breslow</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Homoenolate"
                            >Homoenolate</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Enolate"
                            >Enolate</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Acyl Azolium"
                            >Acyl Azolium</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Dienolate"
                            >Dienolate</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="A,B-Unsaturated Acylazoliums"
                            >α,β-Unsaturated Acylazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="NHC-bound Radical Intermediate"
                            >NHC-bound Radical Intermediate</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Dienenyl Acylazolium Intermediat"
                            >Dienenyl Acylazolium Intermediat</a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>




          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Reaction mechanism
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="KR"
                            >KR</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="DKR"
                            >DKR</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Desymmetrization"
                            >Desymmetrization</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Esterification"
                            >Esterification</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Cyclization reaction (Aromatization reaction)"
                            >Cyclization reaction (Aromatization reaction)</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Dearomatization"
                            >Dearomatization</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          

          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Free radical generation mode
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Photosensitizer"
                            >Photosensitizer</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Halogenated benzene"
                            >Halogenated benzene</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Strong alkali"
                            >Strong alkali</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showBrowseDetail"
                            name="Photocatalysis"
                            >Photocatalysis</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>
        </ul>



      </div>

      <div
        v-show="browseDetailRight"
        style="width: 45%; display: inline-block; float: left"
      >
        <div
          style="
            background-color: rgb(214, 79, 56);
            color: white;
            font-size: 26px;
            display: inline-block;
          "
          v-html="this.category"
        ></div>
        <div style="display: block; height: 0px"></div>
        <el-table
          :data="
            browseReactions.slice(
              (currentPage - 1) * pagesize,
              currentPage * pagesize
            )
          "
          border
          style="width: 100%"
        >
          <el-table-column
            label="Entries"
            header-class-name="header-class"
            cell-class-name="cell-class"
            flex="1"
            class="header-class"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pagesize }}
            </template>
          </el-table-column>
          <!-- pro参数指定了该列的scope.row的值是browseReactions的哪个属性 -->
          <el-table-column
            prop="basereacion"
            label="Reactions"
            flex="1"
            class="header-class"
            align="center"
          >
            <template slot-scope="scope">
              <img
                :src="
                  '/api/pre/' +
                  scope.row['basereacion']
                    .replace('.tif', '.png')
                    .replace(' ', '')
                "
                @click="
                  EnterPaperDetailType = 0;
                  lookDetails(
                    1,
                    currentPage,
                    pagesize,
                    scope.row,
                    scope.$index + (currentPage - 1) * pagesize
                  );
                "
                alt="-"
                class="image-hover-effect"
                style="width: 50%"
              />
            </template>
          </el-table-column>
        </el-table>

        <div style="display: flex; justify-content: center">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[1, 2, 3, 4]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="browseReactions.length"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <div
      v-show="this.downloadShow"
      class="detail"
      style="margin-left: 15%; margin-right: 15%"
    >



      <!--现在download页面左侧的导航栏-->
      <div
        style="
          background-color: rgb(214, 216, 230);
          width: 35%;
          min-height: 100vh;
          display: inline-block;
          float: left;
        "
        class="browseleft"
      >

      
        <strong>Reaction Condition</strong>

        <ul>

          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Solvents
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="THF"
                            >THF</a
                          ></el-dropdown-item>
                        

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="CH2Cl2"
                            >CH<sub>2</sub>Cl<sub>2</sub></a
                          ></el-dropdown-item
                        >

                        
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="14Dioxane"
                            >1,4-Dioxane</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="CHCl3"
                            >CHCl<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="PhMe"
                            >PhMe</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="MeCN"
                            >MeCN</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="EtOAc"
                            >EtOAc</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="DMF"
                            >DMF</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="CCl4"
                            >CCl<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="EtOH"
                            >EtOH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="MeOH"
                            >MeOH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="tAmOH"
                            ><i>t</i>AmOH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="tBuOH"
                            ><i>t</i>BuOH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="C2H4Cl2"
                            >C<sub>2</sub>H<sub>4</sub>Cl<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Mesitylene"
                            >Mesitylene</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="MTBE"
                            >MTBE</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Hexane"
                            >Hexane</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Furan"
                            >Furan</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="DMSO"
                            >DMSO</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Et2O"
                            >Et<sub>2</sub>O</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>
          <!-- 带滚动条的下拉框 -->
          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Bases
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu class="project-dropdown"  slot="dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Cs2CO3"
                            >Cs<sub>2</sub>CO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="DIPEA"
                            >DIPEA</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="NaOAc"
                            >NaOAc</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="CsOAc"
                            >CsOAc</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Et3N"
                            >Et<sub>3</sub>N</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="DBU"
                            >DBU</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="DBN"
                            >DBN</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="DABCO"
                            >DABCO</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="K2CO3"
                            >K<sub>2</sub>CO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="K3PO4"
                            >K<sub>3</sub>PO<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="KOAc"
                            >KOAc</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="KHCO3"
                            >KHCO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Na2CO3"
                            >Na<sub>2</sub>CO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="DMAP"
                            >DMAP</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="TMEDA"
                            >TMEDA</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="NaOBz"
                            >NaOBz</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="LiHMDS"
                            >LiHMDS</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="tBuONa"
                            ><i>t</i>BuONa</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="tBuOK"
                            ><i>t</i>BuOK</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Rb2CO3"
                            >Rb<sub>2</sub>CO<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="MTBD"
                            >MTBD</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="nBuLi"
                            >nBuLi</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="NaH"
                            >NaH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="KH"
                            >KH</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="KHMDS"
                            >KHMDS</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Temperature
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="-78C"
                            >-78 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="-40C"
                            >-40 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="-25to-20C"
                            >-25 to -20 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="10to-5C"
                            >-10 to -5 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="0C"
                            >0 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="10C"
                            >10 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="rt"
                            >rt</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="30C"
                            >30 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="40C"
                            >40 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="50C"
                            >50 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="60C"
                            >60 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="65C"
                            >65 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="70C"
                            >70 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="80C"
                            >80 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="100C"
                            >100 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="120C"
                            >120 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="130C"
                            >130 °C</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="160C"
                            >160 °C</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Atmosphere
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="air"
                            >air</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="O2"
                            >O<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N2"
                            >N<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Ar"
                            >Ar</a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Oxidants
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="DQ"
                            >DQ</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="MnO2"
                            >MnO<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="TEMPO"
                            >TEMPO</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="TsCl"
                            >TsCl</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Halogenatedbenzene"
                            >Halogenated benzene</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="O2"
                            >O<sub>2</sub></a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Additives
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="MolecularSieves"
                            >Molecular Sieves</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="LiCl"
                            >LiCl</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Mg(OtBu)2"
                            >Mg(O<i>t</i>Bu)<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Sc(OTf)3"
                            >Sc(OTf)<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Mg(OTf)2"
                            >Mg(OTf)<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Cu(OTf)2"
                            >Cu(OTf)<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Zn(OTf)2"
                            >Zn(OTf)<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="BHT"
                            >BHT</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="La(OTf)3"
                            >La(OTf)<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="tBuOLi"
                            ><i>t</i>BuOLi</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Catechol"
                            >Catechol</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="BuCO2H"
                            >BuCO<sub>2</sub>H</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="NaBF4"
                            >NaBF<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Ti(OiPr)4"
                            >Ti(O<i>i</i>Pr)<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="2-ClPhCO2Na"
                            >2-ClPhCO<sub>2</sub>Na</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="HFIP"
                            >HFIP</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="S8"
                            >S<sub>8</sub></a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>
        </ul>

      
        <strong>NHC-Catalysts</strong>
        <ul>
          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >NHC-types
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Aminoindane-based Triazoliums"
                            >Aminoindane-based Triazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Morpholine-based Triazoliums"
                            >Morpholine-based Triazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Pyrrolidine-based Triazoliums"
                            >Pyrrolidine-based Triazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Acyclic Triazoliums"
                            >Acyclic Triazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Oxazolidine-based Heterazoliums"
                            >Oxazolidine-based Heterazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Imidazoline-based Heterazoliums"
                            >Imidazoline-based Heterazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Imidazole-based Heterazoliums"
                            >Imidazole-based Heterazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Thiazole-based Heterazoliums"
                            >Thiazole-based Heterazoliums</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>

          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >N-substituent
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-Ph"
                            >N-Ph</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-4-Cl-C6H4"
                            >N-4-Cl-C6H4</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-Mes"
                            >N-Mes</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-4-OMe-C6H4"
                            >N-4-OMe-C<sub>6</sub>H<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-C6F5"
                            >N-C<sub>6</sub>F<sub>5</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-2,6-Et-C6H3"
                            >N-2,6-Et-C<sub>6</sub>H<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-C6H2Cl3"
                            >N-C<sub>6</sub>H<sub>2</sub>Cl<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-2,6-iPr-C6H3"
                            >N-2,6-<i>i</i>Pr-C<sub>6</sub>H<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-tBu"
                            >N-<i>t</i>Bu</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-2,6-OMe-C6H3"
                            >N-2,6-OMe-C<sub>6</sub>H<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-C6H2Cl3"
                            >N-C<sub>6</sub>H<sub>2</sub>Cl<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-3Cyclic-C6H2"
                            >N-3Cyclic-C<sub>6</sub>H<sub>2</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-C6H2Br3"
                            >N-C<sub>6</sub>H<sub>2</sub>Br<sub>3</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-4-CF3-C6H4"
                            >N-4-CF<sub>3</sub>-C<sub>6</sub>H<sub>4</sub></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-3Me-C6H2"
                            >N-3Me-C<sub>6</sub>H<sub>2</sub></a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >X- of NHC-HX
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="BF4"
                            >BF<sub>4</sub><sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Cl-"
                            >cl<sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="ClO4-"
                            >ClO<sub>4</sub><sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Br-"
                            >Br<sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="OTf-"
                            >OTf<sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="I-"
                            >I<sup>-</sup></a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="PF6-"
                            >PF<sub>6</sub><sup>-</sup></a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>
        </ul>


              
        <strong>Reaction Modes</strong>
        <ul>
          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Reaction types
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Benzoin"
                            >Benzoin</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Stetter"
                            >Stetter</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Radical Couplling"
                            >Radical Couplling</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Remote Activation"
                            >Remote Activation</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Esterification"
                            >Esterification</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="B-hydydroxylation"
                            >β-hydydroxylation</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Desymmetrization"
                            >Desymmetrization</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Single Electron Transfer"
                            >Single Electron Transfer</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>

          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Annulation Reactions
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="[2+4]"
                            >[2+4]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="[2+3]"
                            >[2+3]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="[3+3]"
                            >[3+3]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="[4+3]"
                            >[4+3]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="[2+2]"
                            >[2+2]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="[2+1]"
                            >[2+1]</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="[2+2+2]"
                            >[2+2+2]</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >New bond formed
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="C-N"
                            >C-N</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="C-C"
                            >C-C</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="C-O"
                            >C-O</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="C-S"
                            >C-S</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="C-P"
                            >C-P</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="C-B"
                            >C-B</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="C-Si"
                            >C-Si</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="C-F"
                            >C-F</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-N"
                            >N-N</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Substrate types
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Aryl aldehyde"
                            >Aryl aldehyde</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Alkyl aldehyde "
                            >Alkyl aldehyde </a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Enal"
                            >Enal</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Chorloaldehyde"
                            >Chorloaldehyde</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Dienyl aldehyde"
                            >Dienyl aldehyde</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Cyclic aldehyde or aza-cyclic aldehyde"
                            >Cyclic aldehyde or aza-cyclic aldehyde</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="B-methyl cinnamic acid"
                            >β-methyl cinnamic acid</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="A,B-unsaturated acyl chloride"
                            >α,β-unsaturated acyl chloride</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="A-bromoenal"
                            >α-bromoenal</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="B-methyllenal"
                            >β-methyllenal</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="B-formylenal"
                            >β-formylenal</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Chiral type
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Carbon centered chirality"
                            >Carbon centered chirality</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Sulfur center chirality"
                            >Sulfur center chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Phosphorus center chirality"
                            >Phosphorus center chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Nitrogen center chirality"
                            >Nitrogen center chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="C-C Axial chirality"
                            >C-C Axial chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="C-N Axial chirality"
                            >C-N Axial chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="N-N Axial chirality"
                            >N-N Axial chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Plananr chirality"
                            >Plananr chirality</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Spiral Chirality"
                            >Spiral Chirality</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Reactive components
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Intermolecular Reaction"
                            >Intermolecular Reaction</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Intramolecular Reaction"
                            >Intramolecular Reaction</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Multicomponent Reaction"
                            >Multicomponent Reaction</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>




          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Key catalyitic intermediates
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Breslow"
                            >Breslow</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Homoenolate"
                            >Homoenolate</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Enolate"
                            >Enolate</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Acyl Azolium"
                            >Acyl Azolium</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Dienolate"
                            >Dienolate</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="A,B-Unsaturated Acylazoliums"
                            >α,β-Unsaturated Acylazoliums</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="NHC-bound Radical Intermediate"
                            >NHC-bound Radical Intermediate</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Dienenyl Acylazolium Intermediat"
                            >Dienenyl Acylazolium Intermediat</a
                          ></el-dropdown-item
                        >

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>




          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Reaction mechanism
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="KR"
                            >KR</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="DKR"
                            >DKR</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Desymmetrization"
                            >Desymmetrization</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Esterification"
                            >Esterification</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Cyclization reaction (Aromatization reaction)"
                            >Cyclization reaction (Aromatization reaction)</a
                          ></el-dropdown-item
                        >

                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Dearomatization"
                            >Dearomatization</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>


          

          <li style="color: rgb(20, 54, 95)">
            <div class="b5" style="margin-top: 2px; margin-left: -5px">
              <span style="background-color: rgb(214, 216, 230); color: black">
                <el-row class="block-col-2">
                  <el-col :span="12">
                    <el-dropdown>
                      <span
                        class="el-dropdown-link"
                        style="
                          background-color: rgb(214, 216, 230);
                          padding: 4px;
                          color: rgb(20, 54, 95);
                        "
                      >Free radical generation mode
                        <i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="project-dropdown">
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Photosensitizer"
                            >Photosensitizer</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Halogenated benzene"
                            >Halogenated benzene</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Strong alkali"
                            >Strong alkali</a
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          ><a
                            @click="showDownloadDetail"
                            name="Photocatalysis"
                            >Photocatalysis</a
                          ></el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </span>
            </div>
          </li>
        </ul>



      </div> 


      <div
        v-show="
          this.showDownloadDetailRight == false && this.downloadShow == true
        "
        style="display: grid; place-items: center; height: 100vh"
      >
        <div
          v-show="
            this.showDownloadDetailRight == false && this.downloadShow == true
          "
          style="font-size: 30px;padding-left:17vw;"
        >
          Please select the category on the left
        </div>
      </div>

      <div
        v-show="this.showDownloadDetailRight"
        style="width: 65%; display: inline-block; float: right"
      >
        <span style="float: left; padding-top: 5px; padding-right: 10px">
          <div style="padding-left:10px,margin-top:10px">
            Total {{ this.downloadData_all.length }} results
          </div>
        </span>

        <span
          style="
            float: right;
            padding-top: 5px;
            padding-right: 10px;
            cursor: pointer;
          "
          @click="downloadPapers"
        >
          <div style="display: flex; justify-content: center">
            <img
              src="@/assets/icon/download.svg"
              class="download_icon"
              style="height: 20px"
            />
          </div>
          <div>
            <div class="download">Download</div>
          </div>
        </span>
        <el-table
          :data="
            downloadData_all.slice(
              (currentPage - 1) * pagesize,
              currentPage * pagesize
            )
          "
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          border
          style="width: 100%"
        >
          <el-table-column type="selection" align="center"></el-table-column>

          <el-table-column label="Entry" align="center">
            <template v-slot="{ $index }">
              {{ $index + 1 + (currentPage - 1) * pagesize }}
            </template>
          </el-table-column>

          <el-table-column prop="title" label="Titles" align="center">
            <template v-slot="scope">
              <span
                v-html="formatTitle(scope.row.title)"
                @click="
                  EnterPaperDetailType = 2;
                  lookDetails(
                    1,
                    currentPage,
                    pagesize,
                    scope.row,
                    scope.$index + (currentPage - 1) * pagesize
                  );
                "
                class="image-hover-effect"
              ></span>
            </template>
          </el-table-column>

          <el-table-column
            prop="journal"
            label="Journals"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="publishtime"
            label="PublishTime"
            align="center"
          >
            <template slot-scope="scope">
              <div v-html="formatDate(scope.row.publishtime)"></div>
            </template>
          </el-table-column>
        </el-table>
        <div style="display: flex; justify-content: center">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[1, 2, 3, 4]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="downloadData_all.length"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoleculeIndex from "./components/MoleculeIndex";
import JSME from "@/components/JSME.vue";
import axios from "axios";
export default {
  name: "App",
  components: {
    JSME,
    MoleculeIndex,
  },
  data() {
    return {
      searchKey: 1,
      activeKey: "ketcher",
      year: 2024,
      ketcher: null,

      ketcherSmiles: null,
      jsmeSmiles: null,
      currentSmiles: null,
      download: "",
      basereaction: "",
      Addition: "",
      smiles: "",
      tableData2: [],
      homeShow: false,
      searchBox: false,
      searchBox2All: false,
      searchBox2Table: false,
      searchBox2Pic: false,
      guideShow: false,
      browseShow: false,
      browseShow1: false,
      browseShow2: false,
      browseShow3: false,

      aboutShow: false,
      browseDetail: false,
      show: false,
      downloadShow: false,
      showDownloadDetailRight: false,
      browseDetailRight: false,
      downloadData_all: [],
      detailData: {},
      rownum: 0,
      t: "",
      imgUrl: "",
      arr: [],
      total: 50,
      OrigntableData: [],
      category: null,
      brief: null,
      yield2: [],
      rr: [],
      ee: [],
      er: [],
      dr: [],
      ez: [],
      currentPage: 1,
      pagesize: 1,
      currentPage2: 1,
      pagesize2: 1,
      browseReactions: [],
      paperId: 1,
      downloadTotal: 50,
      selectedRowsUrls: [],
      smilestopng:"",
    };
  },
  methods: {
    Allfalse() {
      this.searchBox = false;
      this.searchBox2All = false;
      this.searchBox2Table = false;
      this.searchBox2Pic = false;
      this.show = false;
      this.homeShow = false;
      this.guideShow = false;
      this.aboutShow = false;
      this.browseShow = false;
      this.browseShow1 = false;
      this.browseShow2 = false;
      this.browseShow3 = false;
      this.browseDetail = false;
      this.downloadShow = false;
      this.showDownloadDetailRight = false;
      this.browseDetailRight = false;
      this.currentPage = 1;
      this.pagesize = 1;
      this.currentPage2 = 1;
      this.pagesize2 = 1;
    },
    isNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
    formatYield(value) {
      return this.isNumeric(value) ? (parseFloat(value) * 100).toFixed(0) + '%' : value; // toFixed(2)表示保留至小数点后两位
    },
    returnList() {
      var cp = this.currentPage;
      var ps = this.pagesize;
      var cp2 = this.currentPage2;
      var ps2 = this.pagesize2;
      this.Allfalse();
      this.currentPage = cp;
      this.pagesize = ps;
      this.currentPage2 = cp2;
      this.pagesize2 = ps2;
      if (this.EnterPaperDetailType === 0) {
        this.browseDetail = true;
        this.browseDetailRight = true;
      } else if (this.EnterPaperDetailType === 1) {
        this.searchBox2All = true;
      } else if (this.EnterPaperDetailType === 3) {
        this.searchBox2Pic = true;
      } else if (this.EnterPaperDetailType === 4) {
        this.searchBox2Table = true;
      } else if (this.EnterPaperDetailType === 2) {
        this.downloadShow = true;
        this.showDownloadDetailRight = true;
      }
    },
    handleSizeChange(size) {
      this.pagesize = size;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },
    handleSizeChange2(size2) {
      this.pagesize2 = size2;
    },
    handleCurrentChange2(currentPage2) {
      this.currentPage2 = currentPage2;
    },
    async lookDetails(flag, cp, ps, d, rownum) {
      if (d.hasOwnProperty("Titles")) {
        this.t = d.Titles;
        console.log("d.Titles:",d.Titles)
      } else {
      }
      if (flag === 1) {
        this.currentPage = cp;
        this.pagesize = ps;
      } else if (flag === 2) {
        this.currentPage2 = cp;
        this.pagesize2 = ps;
      }
      this.paperId = d.id;
      this.rownum = rownum;
      await axios
        .post("/api/searchById", {
          id: d.id,
        })
        .then((response) => {
          if (response.data.code == 200) {
            // detailData存储文章详情页面的作者、摘要、关键词
            console.log("response.data.data:",response.data.data)
            this.detailData = response.data.data[0];
            // 加上下面这句就可以正确显示title了
            this.t = response.data.data[0].title;
            try {
              if (typeof this.detailData.conditions === "string") {
                this.tableData2 = JSON.parse(this.detailData.conditions);
              } else if (Array.isArray(this.detailData.conditions)) {
                this.tableData2 = JSON.parse(this.detailData.conditions[0]);
              }
              this.tableData2 = JSON.parse(this.detailData.conditions[0]);
            } catch (error) {
              console.error("解析 JSON 数据时出错:", error);
            }
            if (d.id === 187) {
              this.tableData2.idis187 = 1;
              this.tableData2.forEach((item, index) => {
                item.pic = 0;
                if (item.Addition && item.Addition.includes(",")) {
                  item.Addition = item.Addition.split(",")[0];
                }
                if (item.Addition && item.Addition.includes(".tif")) {
                  item.Addition =
                    "/api/pre/" +
                    item.Addition.replace(".tif", ".png").replace(" ", "");
                }
                if (
                  item.Addition &&
                  (item.Addition.includes(".tif") ||
                    item.Addition.includes(".png") ||
                    item.Addition.includes(".jpg"))
                ) {
                  item.pic = 1;
                }
                if (item.hasOwnProperty("NHC-structure")) {
                  if (
                    item["NHC-structure"] != null &&
                    item["NHC-structure"].includes(",")
                  ) {
                    item["NHC-Structure"] = item["NHC-structure"].split(",")[0];
                    item["NHC-Structure"] = item["NHC-structure"];
                  }
                  if (
                    item["NHC-structure"] != null &&
                    item["NHC-structure"].includes(".tif")
                  ) {
                    item["NHC-structure"] =
                      "/api/pre/" +
                      item["NHC-structure"]
                        .replace(".tif", ".png")
                        .replace(" ", "");
                    item["NHC-Structure"] = item["NHC-structure"];
                  }
                  if (
                    (item["NHC-structure"] != null &&
                      item["NHC-structure"].includes(".tif")) ||
                    item["NHC-structure"].includes(".png") ||
                    item["NHC-structure"].includes(".jpg")
                  ) {
                  }
                }

                if (item.hasOwnProperty("NHC-Structure")) {
                  if (
                    item["NHC-Structure"] != null &&
                    item["NHC-Structure"].includes(",")
                  ) {
                    item["NHC-Structure"] = item["NHC-Structure"].split(",")[0];
                  }
                  if (
                    item["NHC-Structure"] != null &&
                    item["NHC-Structure"].includes(".tif")
                  ) {
                    item["NHC-Structure"] =
                      "/api/pre/" +
                      item["NHC-Structure"]
                        .replace(".tif", ".png")
                        .replace(" ", "");
                  }
                  if (
                    item["NHC-Structure"] != null &&
                    (item["NHC-Structure"].includes(".tif") ||
                      item["NHC-Structure"].includes(".png") ||
                      item["NHC-Structure"].includes(".jpg"))
                  ) {
                  }
                }
                if (!item.hasOwnProperty("ee%")) {
                  item["ee%"] = "-";
                }
                if (!item.hasOwnProperty("dr")) {
                  item["dr"] = "-";
                }
                if (
                  !item.hasOwnProperty("Yield(%)") ||
                  !item.hasOwnProperty("yield")
                ) {
                  item["yield"] = "-";
                }
                if (item.hasOwnProperty("Addition-structure")) {
                  if (
                    item["sddition-structure"] != null &&
                    item["Addition-structure"].includes(",")
                  ) {
                    item["Addition-Structure"] =
                      item["Addition-structure"].split(",")[0];
                    item["Addition-Structure"] = item["Addition-structure"];
                  }
                  if (
                    item["Addition-structure"] != null &&
                    item["Addition-structure"].includes(".tif")
                  ) {
                    item["Addition-structure"] =
                      "/api/pre/" +
                      item["Addition-structure"]
                        .replace(".tif", ".png")
                        .replace(" ", "");
                    item["Addition-Structure"] = item["Addition-structure"];
                  }
                  if (
                    (item["Addition-structure"] != null &&
                      item["Addition-structure"].includes(".tif")) ||
                    item["Addition-structure"].includes(".png") ||
                    item["Addition-structure"].includes(".jpg")
                  ) {
                  }
                }
                if (item.hasOwnProperty("Addition-Structure")) {
                  if (
                    item["Addition-Structure"] != null &&
                    item["Addition-Structure"].includes(",")
                  ) {
                    item["Addition-Structure"] =
                      item["Addition-Structure"].split(",")[0];
                  }
                  if (
                    item["Addition-Structure"] != null &&
                    item["Addition-Structure"].includes(".tif")
                  ) {
                    item["Addition-Structure"] =
                      "/api/pre/" +
                      item["Addition-Structure"]
                        .replace(".tif", ".png")
                        .replace(" ", "");
                  }
                  if (
                    item["Addition-Structure"] != null &&
                    (item["Addition-Structure"].includes(".tif") ||
                      item["Addition-Structure"].includes(".png") ||
                      item["Addition-Structure"].includes(".jpg"))
                  ) {
                  }
                }
              });
            } else {
              // this.tableData2就是文章详情页的Condition表
              this.tableData2.idis187 = 0;
              this.tableData2.forEach((item, index) => {
                item.pic = 0;
                if (item.Addition && item.Addition.includes(",")) {
                  item.Addition = item.Addition.split(",")[0];
                }
                if (item.Addition && item.Addition.includes(".tif")) {
                  item.Addition =
                    "/api/pre/" +
                    item.Addition.replace(".tif", ".png").replace(" ", "");
                }
                if (
                  item.Addition &&
                  (item.Addition.includes(".tif") ||
                    item.Addition.includes(".png") ||
                    item.Addition.includes(".jpg"))
                ) {
                  item.pic = 1;
                }
                if (item.hasOwnProperty("NHC-structure")) {
                  if (
                    item["NHC-structure"] != null &&
                    item["NHC-structure"].includes(",")
                  ) {
                    item["NHC-Structure"] = item["NHC-structure"].split(",")[0];
                    item["NHC-Structure"] = item["NHC-structure"];
                  }
                  if (
                    item["NHC-structure"] != null &&
                    item["NHC-structure"].includes(".tif")
                  ) {
                    item["NHC-structure"] =
                      "/api/pre/" +
                      item["NHC-structure"]
                        .replace(".tif", ".png")
                        .replace(" ", "");
                    item["NHC-Structure"] = item["NHC-structure"];
                  }
                  if (
                    (item["NHC-structure"] != null &&
                      item["NHC-structure"].includes(".tif")) ||
                    item["NHC-structure"].includes(".png") ||
                    item["NHC-structure"].includes(".jpg")
                  ) {
                  }
                }
                if (item.hasOwnProperty("NHC-Structure")) {
                  if (
                    item["NHC-Structure"] != null &&
                    item["NHC-Structure"].includes(",")
                  ) {
                    item["NHC-Structure"] = item["NHC-Structure"].split(",")[0];
                  }
                  if (
                    item["NHC-Structure"] != null &&
                    item["NHC-Structure"].includes(".tif")
                  ) {
                    item["NHC-Structure"] =
                      "/api/pre/" +
                      item["NHC-Structure"]
                        .replace(".tif", ".png")
                        .replace(" ", "");
                  }
                  if (
                    item["NHC-Structure"] != null &&
                    (item["NHC-Structure"].includes(".tif") ||
                      item["NHC-Structure"].includes(".png") ||
                      item["NHC-Structure"].includes(".jpg"))
                  ) {
                  }
                }
                if (!item.hasOwnProperty("ee%")) {
                  item["ee%"] = "-";
                }
                if (!item.hasOwnProperty("dr")) {
                  item["dr"] = "-";
                }
                if (
                  !item.hasOwnProperty("Yield(%)") ||
                  !item.hasOwnProperty("yield")
                ) {
                  item["yield"] = "-";
                }
              });
            }
            let arr = this.detailData.products.split(",");
            arr = arr.map((path) => {
              return { url: path.replace(".tif", ".png").replace(" ", "") };
            });
            // arr是products那个表的图片列的url
            this.arr = arr;
            let yield2 = this.detailData.yield.split(",");
            this.yield2 = yield2;

            let rr = this.detailData.rr.split(",");
            this.rr = rr;

            let ee = this.detailData.ee.split(",");
            this.ee = ee;

            let er = this.detailData.er.split(",");
            this.er = er;

            let dr = this.detailData.dr.split(",");
            this.dr = dr;

            let ez = this.detailData.ez.split(",");
            this.ez = ez;

            this.imgUrl = this.detailData.mechanism
              .replace(".tif", ".png")
              .replace(" ", "");
            this.imgUrl = this.imgUrl.replace(" ", "");
            this.basereaction = this.detailData.basereaction
              .replace(".tif", ".png")
              .replace(" ", "");
            this.download =
              "/api/pre/" + this.detailData.paperpdfpath;
            this.Allfalse();
            this.show = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    home() {
      this.Allfalse();
      this.homeShow = true;
    },
    search() {
      this.Allfalse();
      this.searchBox = true;
    },
    downloadPage() {
      this.Allfalse();
      this.EnterPaperDetailType = 2;
      this.downloadShow = true;
      this.showDownloadDetailRight = false;
    },
    browse() {
      this.Allfalse();
      this.browseShow1 = true;
    },
    browse1() {
      this.Allfalse();
      this.browseShow1 = true;
    },
    browse2() {
      this.Allfalse();
      this.browseShow2 = true;
    },
    browse3() {
      this.Allfalse();
      this.browseShow3 = true;
    },
    guide() {
      this.Allfalse();
      this.guideShow = true;
    },
    about() {
      this.Allfalse();
      this.aboutShow = true;
    },
    paginateArray(array, page = 1) {
      const pageSize = 2;
      if (page === 1) {
        return array.slice(0, pageSize);
      }
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const pageItems = array.slice(startIndex, endIndex);
      return pageItems;
    },
    async onSearch() {
      // 如果输入框和画板同时有内容则以输入框的为准
      
      // 画板有内容或者输入框及画板都没有内容
      if(this.smiles === ""){
        this.getSmiles();
        return;
      }
      // 输入框有内容
      var selectElement = document.querySelector('select[name="selectAll"]');
      var selectedValue = selectElement.value;
      var para = {
        title: this.smiles,
        option: 0,
      };
      if (selectedValue === "all") {
        para = {
          title: this.smiles,
          option: 0,
        };
      } else if (selectedValue === "reactions") {
        para = {
          title: this.smiles,
          option: 1,
        };
      } else if (selectedValue === "references") {
        para = {
          title: this.smiles,
          option: 2,
        };
      }
      await axios
        .post("/api/searchByTitle", para)
        .then((response) => {
          if (response.data.code == 200) {
            this.OrigntableData = JSON.parse(
              JSON.stringify(response.data.data)
            );
            this.tableData = this.paginateArray(this.OrigntableData, 1);
            this.total = response.data.data.length;
            this.Allfalse();
            if (selectedValue) {
              if (selectedValue === "all") {
                this.EnterPaperDetailType = 1;
                this.searchBox2All = true;
              } else if (selectedValue === "reactions") {
                this.EnterPaperDetailType = 3;
                this.searchBox2Pic = true;
              } else if (selectedValue === "references") {
                this.EnterPaperDetailType = 4;
                this.searchBox2Table = true;
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

      // 暂时还未使用到这个函数
      async smilestopngfunction(e) {
        this.smilestopng = "CC1=CC(C)=CC(C)=C1[N+]2=CN3C(COC[C@@H]3CC4=CC=CC=C4)=N2.[Cl-]";
        var para = {
          smiles: this.smilestopng,
        };
        await axios
        .post("/api/smiles_to_png", para)
        .then((response) => {
          // 将Base64字符串转换为图片
          var img = new Image();
          img.src = 'data:image/png;base64,' + response.data.image;
          // 清空 <div> 的内容
          this.$refs.imageContainer.innerHTML = '';
          // 将图片添加到DOM中
          // document.body.appendChild(img);
          // 创建新的图片元素
          // let img = document.createElement('img');
          // 将新的图片元素添加到 <div> 中
          this.$refs.imageContainer.appendChild(img);
      });
    },







    // 读取画板内容转为smiles
    getSmiles() {
      if (this.activeKey === "ketcher") {
        if (!this.ketcher) {
          this.initKetcher();
        }
        this.ketcher
          .getSmiles()
          .then(async (res) => {
            this.ketcherSmiles = res;
            if (this.ketcherSmiles === "") {
              // 此时画板和搜索框都没有内容 
              this.$message({
                message: "Please enter keywords before searching",
                type: "error",
              });
              return;              
            }
            this.smiles = this.ketcherSmiles;
            var selectElement = document.querySelector(
              'select[name="selectAll"]'
            );
            var selectedValue = selectElement.value;
            var para = {
              title: this.smiles,
              option: 0,
            };
            if (selectedValue === "all") {
              para = {
                title: this.smiles,
                option: 0,
              };
            } else if (selectedValue === "reactions") {
              para = {
                title: this.smiles,
                option: 1,
              };
            } else if (selectedValue === "references") {
              para = {
                title: this.smiles,
                option: 2,
              };
            }
            console.log("this.smiles:",this.smiles)
            await axios
              .post("/api/searchByTitle", para)
              .then((response) => {
                if (response.data.code == 200) {
                  this.OrigntableData = JSON.parse(
                    JSON.stringify(response.data.data)
                  );
                  this.tableData = this.paginateArray(this.OrigntableData, 1);
                  this.total = response.data.data.length;
                  this.Allfalse();
                  if (selectedValue) {
                    if (selectedValue === "all") {
                      this.EnterPaperDetailType = 1;
                      this.searchBox2All = true;
                    } else if (selectedValue === "reactions") {
                      this.EnterPaperDetailType = 3;
                      this.searchBox2Pic = true;
                    } else if (selectedValue === "references") {
                      this.EnterPaperDetailType = 4;
                      this.searchBox2Table = true;
                    }
                  }
                }
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((e) => {});
      } else {
        this.jsmeSmiles = this.currentSmiles;
      }
    },
    async showBrowseDetail(e) {
      const links = document.querySelectorAll("a");
      links.forEach((link) => {
        link.style.textDecoration = "none";
      });
      e.target.style.textDecoration = "underline";
      await axios
        .post("/api/category", {
          Cname: e.target.name,
        })
        .then((response) => {
          if (response.data.code == 200) {
            if (e.target.innerText.includes("Cycloaddition")) {
              this.category = "Annulation : " + e.target.innerHTML;
            } else {
              this.category = e.target.innerHTML;
            }
            (this.brief = response.data.data[0].brief),
              (this.browseReactions = response.data.data.slice(1));
              this.browseShow = false;
              this.browseShow1 = false;
              this.searchBox = false;
              this.browseShow2 = false;
              this.browseShow3 = false;
              this.browseDetail = true;
              this.browseDetailRight = true;
          }})
        .catch((error) => {
          (this.category = "null"), (this.brief = "null");
          this.$message({
            message: "No relevant data available!",
            type: "error",
          });
          this.browseDetailRight = false;
          return;
        });
    },

    async showDownloadDetail(e) {
      // console.log("e.target.name:",e.target.name)
      const links = document.querySelectorAll("a");
      links.forEach((link) => {
        link.style.textDecoration = "none";
      });
      e.target.style.textDecoration = "underline";
      await axios
        .post("/api/downloadxzy", {
          Cname: e.target.name,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.downloadData_all = JSON.parse(
              JSON.stringify(response.data.data)
            );
            this.Allfalse();
            this.downloadShow = true;
            this.showDownloadDetailRight = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    initKetcher() {
      let ketcherFrame = document.getElementById("idKetcher");
      let ketcher = null;
      if ("contentDocument" in ketcherFrame) {
        ketcher = ketcherFrame.contentWindow.ketcher;
      } else {
        ketcher = document.frames["idKetcher"].window.ketcher;
      }
      this.ketcher = ketcher;
    },
    changeSmiles(val) {
      this.currentSmiles = val;
    },
    changeTab() {
      this.currentSmiles = "";
      this.ketcherSmiles = "";
      this.jsmeSmiles = "";
    },
    handleSelectionChange(val) {
      this.selectedRowsUrls = this.$refs.multipleTable.selection;
    },
    formatTitle(title) {
      const words = title.split(" ");
      const smilesArray = this.smiles.split(",");
      return words
        .map((word) => {
          const lowercaseWord = word.toLowerCase();
          const lowercaseSmiles = smilesArray.map((smile) =>
            smile.toLowerCase()
          );
          if (lowercaseSmiles.includes(lowercaseWord)) {
            return `<strong style="background-color:rgb(209,237,239)">${word}</strong>`;
          } else {
            return word;
          }
        })
        .join(" ");
    },
    formatDate(dateString) {
      if (dateString.match(/^\d{2}-[A-Za-z]+-\d{4}$/)) {
        return dateString;
      }
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const dateObj = new Date(dateString);
      if (isNaN(dateObj.getTime())) {
        return dateString;
      }
      const day = dateObj.getDate();
      const month = months[dateObj.getMonth()];
      const year = dateObj.getFullYear();
      return `${day}-${month}-${year}`;
    },
    downloadPapers() {
      this.selectedRowsUrls.forEach((item) => {
        console.log("item.paperpdf:",item.paperpdf)
        console.log("item.title:",item.title)
        this.downloadPDF(
          "/api/pre/" + item.paperpdf,
          item.title
        );
      });
    },
    downloadPDF(url, name) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          var blob = xhr.response;
          var link = document.createElement("a");
          link.style.display = "none";
          link.href = window.URL.createObjectURL(blob);
          console.log("link.href")
          link.setAttribute("download", name + ".pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      };
      xhr.send();
    },
  },
  mounted() {
    this.Allfalse();
    this.homeShow = true;
  },
};
function tif2png(tif) {
  return tif.replace(".tif", ".png").replace(" ", "");
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100vh;

  .actionBar {
    background: rgb(20, 54, 95);
    height: 50px;
    justify-content: center;
    align-content: center;
    align-items: center;
    .el-button {
      color: #ffffff;
      font-weight: 600;
    }
    .result {
      padding: 20px;
      .el-pagination {
        display: flex;
        justify-content: end;
      }
    }
  }

  .myElRow {
    background: rgb(20, 54, 95);
    height: 50px;
    .myElCol {
      color: #ffffff;
      font-weight: 600;
    }
  }
  .molecule {
    padding: 30px;
  }
  .input-search {
    width: 800px;
  }

  .flex {
    display: flex;
  }
  .molecule .left_content {
    margin-top: -10px;
  }

  .molecule .right_content {
    margin-top: 50px;
  }

  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .radio-group {
    display: grid;
  }

  .ant-radio-wrapper {
    padding: 10px 0 !important;
  }
  //search-end

  @font-face {
    font-family: Baskerville;
    src: url("./assets/Baskerville.ttf");
  }
  .myintro1 {
    background-size: cover;
    max-width: 100vw;
    height: 100vh;
    margin: auto;
  }

  .myfle {
    display: inline-block;
    padding-right: 10%;
  }
  .myfle > div {
    width: 100%;
    text-align: center;
  }

  .myfig2 {
    width: 20vw;
    padding-left: 10%;
    margin-top: -5%;
  }

  .myh1 {
    font-size: 26px;
    color: black;
    text-align: center;
    display: inline-block;
    text-align: center;
    display: flex;
    justify-content: center;
    font-family: "Comic Sans MS";
    font-size: 18px;
    font-weight: bold;
  }

  .mytitle {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .myp1 {
    color: black;
    font-family: "Times New Roman";
    font-size: 18px;
  }
  .myp-div {
    margin-left: 10vw;
    margin-right: 10vw;
    display: flex;
  }
  .myintro2 {
    height: 100vh;
    width: 98.75vw;
  }

  p {
    line-height: 1.5;
    font-size: 16px;
  }

  .myintro {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
  }

  .mypic {
    width: 80vw;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    padding-bottom: 0px;
  }

  .my2first > p {
    font-weight: bold;
  }

  .my2note {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Times New Roman";
    font-size: 16px;
    color: rgb(0, 0, 0);
  }

  .my2first {
    padding-top: 2vh;
    padding-left: 2vh;
    font-family: "Times New Roman";
    font-weight: bold;
    font-size: 18px;
    color: rgb(0, 0, 0);
  }

  .my3intro1 {
    background-size: cover;
    max-width: 100vw;
    height: 100vh;
    margin: auto;
  }
  .my3h1 {
    font-size: 26px;
    color: rgb(20, 54, 95);
    text-align: center;
    font-family: Baskerville;
    display: inline-block;
    text-align: center;
    display: flex;
    justify-content: center;
    font-family: "Comic Sans MS";
    font-size: 24px;
    font-weight: bold;
  }

  .my3title {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(214, 216, 230);
    width: 300px;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .my3p1 {
    color: black;
    font-family: "Times New Roman";
    font-size: 18px;
  }
  .my3p-div {
    margin-left: 10vw;
    margin-right: 10vw;
  }
  .my3intro2 {
    height: 100vh;
    width: 98.75vw;
  }

  .my3intro {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
  }

  .my3center {
    text-align: center;
  }
  .header-class {
    font-weight: bold;
    vertical-align: middle;
    text-align: center;
    color: black;
  }

  .cell-class {
    text-align: center;
    vertical-align: middle;
    line-height: normal;
  }

  .browseleft li {
    list-style: none;
  }

  .browseleft li a {
    color: rgb(20, 54, 95);
    text-decoration: none;
  }

  .browse a {
    color: rgb(118, 113, 113);
    text-decoration: none;
  }

  .detailw {
    width: 70%;
    margin: 0 auto;
  }

  .myGuidew {
    width: 70%;
    margin: 0 auto;
  }
  .guidepage {
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    em,
    i {
      font-style: normal;
    }
    li {
      list-style: none;
    }

    img {
      border: 0;
      vertical-align: middle;
    }

    button {
      cursor: pointer;
    }

    a {
      color: #666;
      text-decoration: none;
    }

    a:hover {
      color: #c81623;
    }

    button,
    input {
      font-family: Microsoft YaHei, Heiti SC, tahoma, arial, Hiragino Sans GB,
        "\5B8B\4F53", sans-serif;
      border: 0;
      outline: none;
    }

    body {
      -webkit-font-smoothing: antialiased;
      background-color: #fff;
      font: 12px/1.5 Microsoft YaHei, Heiti SC, tahoma, arial, Hiragino Sans GB,
        "\5B8B\4F53", sans-serif;
      color: #666;
    }

    .hide,
    .none {
      display: none;
    }
    .clearfix:after {
      visibility: hidden;
      clear: both;
      display: block;
      content: ".";
      height: 0;
    }
    .guidew {
      width: 70%;
      margin: 0 auto;
    }

    .shortcut {
      height: 31px;
      line-height: 31px;
      padding-bottom: 50px;
    }

    .fl {
      float: left;
    }

    .fr {
      float: right;
    }

    .shortcut ul li {
      float: left;
    }

    .shortcut .fr ul li:nth-child(even) {
      width: 1px;
      height: 12px;
      margin: 0px 15px 0px;
    }

    .myheader {
      position: relative;
      border-bottom: 1px solid #ccc;
      height: 70px;
    }

    .mylogo {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      width: 171px;
      height: 61px;
      color: green;
      font-size: 16px;
      line-height: 1.5;
    }

    .top-left {
      font-size: 26px;
    }

    .top-right {
      font-size: 16px;
    }

    .main_left {
      position: sticky;
      top: 0;
      width: 0px;
      float: left;
      box-shadow: 2px 2px 5px 0px rgba(142, 139, 139, 0.75);
      color: rgb(168, 169, 176);
      font-size: 16px;
    }
    .guidemain {
      margin-top: 10px;
    }
    .main_middle {
      width: 0px;
      float: left;
    }

    .main_right {
      float: right;
      width: calc(100%);
    }

    .bar_item {
      height: 50px;
      width: 150px;
      text-align: center;
    }
    div a {
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .bar_item:hover {
      background-color: rgb(215, 212, 212);
    }

    .chap {
      box-shadow: 2px 2px 5px 0px rgba(142, 139, 139, 0.75);
      margin-bottom: 20px;
    }
    p {
      margin-top: 10px;
      margin-bottom: 10px;
      color: black;
    }
    .title {
      height: 50px;
      background-color: rgb(214, 216, 230);
      display: flex;
      align-items: center;
      padding-left: 5px;
      color: rgb(20, 54, 95);
      font-size: 16px;
      line-height: 1.5;
    }
    .word {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 30px;
      padding-right: 30px;
      color: black;
      font-size: 2vh;
      line-height: 1.5;
      text-align: justify;
    }

    .pic {
      width: 50%;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 20px;
    }

    .pic_big {
      width: 90%;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 20px;
    }
  }

  .image-hover-effect {
    transition: all 0.3s;
    cursor: default;
  }

  .image-hover-effect:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
    cursor: pointer;
    text-decoration: underline;
  }
  div.cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
    background-color: red;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .bb1 {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 30px;
    .b1tit {
      margin: 0 auto;
      padding: 2px 20px;
      background: #d64f38;
      color: #fff;
      font-size: 24px;
      border-radius: 5px;
    }
    .bb1con {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .left {
        width: 300px;
        border: 1px dotted #e3e6e9;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px 5px 20px;
        .smtt {
          width: 100%;
          text-align: center;
          padding: 2px 20px;
          background: #e3eaf6;
          color: #333;
          font-size: 24px;
          border-radius: 5px;
        }
        .bbcon {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          .mybbbox {
            border-radius: 15px;
            margin-top: 50px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 48%;
            /*+*/
            height:170px;
            background: #e3e6e9;
            &::after {
              content: "";
              position: absolute;
              left: 50%;
              top: -50px;
              transform: translateX(-50%);
              width: 1px;
              height: 50px;
              background-color: #cfcfcf;
            }
            img {
              width: 80%;
            }
            .smtext {
              text-align: center;
              padding: 5px;
              font-weight: bold;
            }
          }
          .bbbox {
            border-radius: 15px;
            margin-top: 50px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 48%;
            background: #e3e6e9;
            &::after {
              content: "";
              position: absolute;
              left: 50%;
              top: -50px;
              transform: translateX(-50%);
              width: 1px;
              height: 50px;
              background-color: #cfcfcf;
            }
            img {
              width: 80%;
            }
            .smtext {
              text-align: center;
              padding: 5px;
              font-weight: bold;
            }
          }
          .nobbboxafter {
            &::after {
              content: "";
              position: absolute;
              left: 50%;
              top: -50px;
              transform: translateX(-50%);
              width: 1px;
              height: 50px;
              /*background-color: #cfcfcf;*/
              background-color: rgba(0, 0, 0, 0);
            }
          }
          a {
            color: inherit; /* 使用父元素的颜色 */
            text-decoration: none;
          }
          a:hover {
          text-decoration: underline;
          }
          a:visited {
            color: inherit; /* 使访问过的链接使用正常颜色 */
          }
        }
      }
      .left1 {
        .bbcon {
          .bbbox {
            padding-top: 20px;
          }
        }
      }
      .left2 {
        width: 380px;
        margin: 0 40px;
        .bbcon {
          .bbbox {
            background: #fff;
            .smtext {
              background-color: #e3e6e9;
              border-radius: 10px;
              width: 70%;
            }
          }
        }
      }
    }
  }
}
.bbb1 {
  padding: 20px 15%;
  .b1tit {
    padding: 2px 20px;
    display: inline-block;
    background: #d64f38;
    color: #fff;
    font-size: 24px;
    border-radius: 5px;
  }
  .concon {
    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .qiu {
        border-radius: 50%;
        background-color: #8a8fb7;
        font-size: 24px;
        color: #fff;
        display: flex;
        text-align: center;
        line-height: 24px;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
      }
      .listbox {
        width: calc(100% - 120px);
        display: flex;
        flex-wrap: wrap;
        .item {
          border: 1px solid #000;
          padding: 5px 10px;
          margin: 0 5px 5px 0;
          border-radius: 5px;
          font-weight: bold;
          a {
            color: inherit; /* 使用父元素的颜色 */
            text-decoration: none;
          }
          a:hover {
          text-decoration: underline;
          }
          a:visited {
            color: inherit; /* 使访问过的链接使用正常颜色 */
          }
        }
        .item:hover {
          background: #000;
          color: #fff;
        }
      }
    }
    .list:nth-child(2n) {
      .qiu {
        background-color: #bbbfd5;
      }
    }
  }
}
.bbb2 {
  padding: 20px 15%;
  .b1tit {
    padding: 2px 20px;
    display: inline-block;
    background: #d64f38;
    color: #fff;
    font-size: 24px;
    border-radius: 5px;
  }
  .concon {
    margin: 0 auto;
    position: relative;
    left: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .list {
      /*background-color: red;*/
      display: flex;
      /*margin: 20px 0;*/
      align-items: center;
      position: relative;
      &::after {
        position: absolute;
        content: "";/*两个节点中的那个划线*/
        /*background: url(./assets/img/6-1.png) no-repeat center center;
        width: 100px;
        height: 100px;*/
        /*width: 30px;
        height: 26px;*/
        left: 134px;
        top: 106px;
      }
      .sj {
        display: inline-block;
        position: relative;
        .tt {
          position: absolute;
          left: 50%;
          top: 50%;
          /*width: 100%;*/
          display: grid;
          place-items: center;
          width: 60%;
          height: 80%;
          text-align: center;
          font-size: 13px;
          font-weight: bold;
          transform: translate(-50%, -50%);
        }
      }
      .box {
        width: 500px;
        margin: 0 20px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        flex-wrap: wrap;
        span {
          padding: 0 5px;
        }
        a {
          color: inherit; /* 使用父元素的颜色 */
          text-decoration: none;
        }
        a:hover {
        text-decoration: underline;
        }
        a:visited {
          color: inherit; /* 使访问过的链接使用正常颜色 */
        }
      }
    }
    .list:nth-child(2n) {
      &::after { /*两个节点中的那个划线*/
        position: absolute;
        content: "";
        /*background: url(./assets/img/7.png) no-repeat center center; */
        width: 30px;
        height: 26px;
        right: 103px;
        left: auto;
        top: 106px;
      }
      flex-direction: row-reverse;
      .box {
        justify-content: flex-end;
      }
    }
    .list:nth-child(1) {
      position: relative;
      left: 40px;
    }
    .list:nth-child(2) {
      position: relative;
      left: -290px;
    }
    .list:nth-child(3) {
      position: relative;
      left: 40px;
    }
    .list:nth-child(4) {
      position: relative;
      left: -290px;
    }
    .list:nth-child(5) {
      position: relative;
      left: 40px;
    }
    .list:nth-child(6) {
      position: relative;
      left: -290px;
    }
    .list:nth-child(7) {
      position: relative;
      left: 40px;
    }
    .list:nth-child(8) {
      position: relative;
      left: -290px;
    }
    .list:nth-child(9) {
      position: relative;
      left: 40px;
    }
    .item:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    .list:last-child {
      &::after {
        content: none;
      }
    }
  }
}
.mycon {
  margin: 30px 0;
  .b1tit {
    padding: 2px 20px;
    display: inline-block;
    background: #d64f38;
    color: #fff;
    font-size: 24px;
    border-radius: 5px;
  }
  .conbox {
    margin: 30px 0;

    width: 1100px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    .item {
      background: url(./assets/img/a.png) no-repeat left center;
      height: 66px;
      margin-bottom: 50px;
      line-height: 78px;
      width: 250px;
      font-size: 22px;
      font-weight: bold;
      position: relative;
      color: #fff;
      text-align: center;
      margin-right: -70px;
      .text {
        width: 110px;
        margin: 0 auto;
        /*margin-left:20px;*/
        white-space: nowrap;
        /*text-align: center;*/
      }
    }
    .item2 {
      .text {
        font-size: 20px;
        margin: 0 0 0 30px;
      }
    }
    .item:nth-child(2n) {
      margin-top: 22px;
      line-height: 54px;
      &::after {
        top: auto !important;
        bottom: -40px;
      }
      background: url(./assets/img/b.png) no-repeat left center;
    }
    .item:last-child {
      text-align: center;
    }
    .loop(@index) when (@index > 0) {
      .item:nth-child(@{index}) {
        &::after {
          content: "@{index}";
          position: absolute;
          left: 50%;
          transform: translateX(-100%);
          top: -50px;
          font-size: 24px;
          color: #8990b8;
        }
      }
      .loop(@index - 1);
    }

    .loop(20);
  }
}

/*下面三项是带有滚动条的下拉框样式*/
.project-dropdown{
  /*设置高度才能显示出滚动条*/
  height:300px;
  overflow: auto;
  scrollbar-color: #888 #f5f5f5; /* 在 Firefox 中定义滚动条的颜色 */
}
/*滚动条本身的样式*/
.project-dropdown::-webkit-scrollbar
{
    width: 5px;
    height: 5px;
    /*background-color: rgb(193,193,193);*/
    /*background-color: red;*/
}
/*滚动条轨道的样式*/
.project-dropdown::-webkit-scrollbar-track
{
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    border-radius: 10px;
    /*background-color: rgb(241,241,241);*/
}



/*th.is-center.is-leaf.el-table__cell{
  background-color: red;
}  这样会把所有的表格的表头都进行设置，而不仅仅是browse页的表头*/ 
</style>
