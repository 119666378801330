import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入VueRouter
// import VueRouter from 'vue-router'
//引入路由器
// import router from './router'

Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(ElementUI);
//应用插件
// Vue.use(VueRouter)
new Vue({
  render: h => h(App),
  // router:router,
}).$mount('#app')

var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?1be554b8bad6ad4d711f195b22217823";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
