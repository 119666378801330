var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{style:({
        width: _vm.width,
        height: _vm.height,
        outline: _vm.jsmeIsLoadedInternal ? '' : '1px solid black',
        outlineOffset: '-.5px',
        textAlign: 'center',
      })},[_c('div',{attrs:{"id":_vm.id}}),(!_vm.jsmeIsLoadedInternal)?_c('div',{style:({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          '-webkit-transform': 'translate(-50%, -50%)',
        })}):_vm._e()]),_c('div',[(_vm.jsmeIsLoadedInternal)?_c('div',[_c('div',{staticStyle:{"width":"100%","display":"flex","flex-direction":"row","flex-wrap":"nowrap"}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }